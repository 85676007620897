import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import {
  ExpandButtonContainer,
  MarginContainer,
  MrlSelectedStepContainer,
  StepNumber,
} from '../styled/MrlStyled';

import { NOT_APPLICABLE } from '../../../constants/general';

const MrlStep = ({
  number,
  step,
  completionStatus,
}) => {
  const userLanguage = useSelector(state => state?.userProfile?.userProfile?.language);

  return (
    <MrlSelectedStepContainer>
      <MarginContainer
        display="flex"
        width="100%"
        marginLeft="0.3vw"
      >
        <MarginContainer
          display="flex"
          justifyContent="center"
          alignItems="center"
          fontSize="16px"
          marginRight="10px"
        >
          <StepNumber isCompleted={step?.isDone}>{number + 1}</StepNumber>

          <MarginContainer paddingBottom="3px" maxWidth="275px" marginLeft="10px">
            <>
              <Typography variant="body2" fontWeight={600} noWrap color="primary">
                {`${step?.name?.[userLanguage] || NOT_APPLICABLE} | ${completionStatus}`}
              </Typography>
              <Typography variant="body2" noWrap>
                {step?.subtitle?.[userLanguage] || NOT_APPLICABLE}
              </Typography>
            </>
          </MarginContainer>
        </MarginContainer>
      </MarginContainer>
      <ExpandButtonContainer>
        <FontAwesomeIcon icon={faChevronRight} color="#2A5AB7" />
      </ExpandButtonContainer>
    </MrlSelectedStepContainer>
  );
};

export default MrlStep;
