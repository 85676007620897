import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Footer = styled(Box)(({ theme }) =>  `
  display: flex;
  gap: ${theme.spacing(30)};
  // position: sticky;
  // bottom: 0;
  // height: 88px;
  background-color: ${theme.palette.common.white};
  width: 100%;
  // padding-top: ${theme.spacing(10)};
  padding: ${theme.spacing(10, 20, 0)};
  
  & button {
    height: fit-content;
  }
`);
