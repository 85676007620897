import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import DialogAlert from '../../../common/components/DialogAlert';
import AddCategoryModal from '../../components/AddCategoryModal';
import ArticlesFilterModal from '../../components/ArticlesFilterModal';
import ArticlesList from '../../components/ArticlesList';
import CategoryList from '../../components/CategoryList';
import MrlLayout from '../../components/MrlLayout';

import { deleteArticle, getAllArticles } from '../../store/articles';
import {
  addCategory,
  deleteCategory,
  editCategory,
  getAllCategories,
} from '../../store/categories';

const DIALOG_TYPES = {
  deleteArticle: 'DELETE_ARTICLE',
  deleteCategory: 'DELETE_CATEGORY',
  category: 'CATEGORY',
};

const Articles = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);
  const [filters, setFilters] = useState({
    visibility: ['all'],
    categories: [],
  });
  const [dialogType, setDialogType] = useState('');
  const [categoryIdToEdit, setCategoryIdToEdit] = useState(null);
  const [toggleValue, setToggleValue] = useState('articles');
  const [articleIdToDelete, setArticleIdToDelete] = useState(null);
  const [categoryIdToDelete, setCategoryIdToDelete] = useState(null);
  const [filteredArticles, setFilteredArticles] = useState([]);

  const categories = useSelector((state) => state.admin.categories.categories) || [];
  const articles = useSelector((state) => state.admin.articles.articles) || [];

  const resetCategoryIdToEdit = () => {
    setCategoryIdToEdit(null);
  };

  const handleCategoriesModalStatus = () => {
    setDialogType(dialogType === DIALOG_TYPES.category ? '' : DIALOG_TYPES.category);
  };

  const handleApplyFilters = (values) => {
    // Destructure filters for better readability
    const { visibility, categories } = values;

    // Filtering based on the visibility and categories
    const newArticles = articles.filter((item) => {
      // Visibility Filter
      let visibilityPass;
      if (visibility[0] === 'all') {
        visibilityPass = true;
      } else if (visibility[0] === 'hidden') {
        visibilityPass = !item.isVisible;
      } else if (visibility[0] === 'visible') {
        visibilityPass = item.isVisible;
      }

      // Categories Filter
      let categoriesPass = true;
      if (categories.length > 0) {
        categoriesPass = categories.includes(item.categoryId._id);
      }

      return visibilityPass && categoriesPass;
    });

    setFilteredArticles(newArticles);
  };

  const handleGetAllArticles = async () => {
    await dispatch(getAllArticles());
  };

  const handleToggleValue = (newValue) => {
    setToggleValue(newValue);
    handleGetAllArticles();
  };

  const createNewArticle = () => {
    history.push('/admin/mrl/article/form');
  };

  const handleGetAllCategories = async () => {
    await dispatch(getAllCategories());
  };

  const createNewCategory = async (formValues) => {
    const payload = formValues;
    await dispatch(addCategory(payload));
    await handleGetAllCategories();
    handleCategoriesModalStatus();
  };

  const onEditCategory = async (categoryId, payload) => {
    await dispatch(editCategory(categoryId, payload));
    await handleGetAllCategories();
    handleCategoriesModalStatus();
    resetCategoryIdToEdit();
  };

  const handleEditCategory = (categoryId, e) => {
    e.stopPropagation();
    handleCategoriesModalStatus();
    setCategoryIdToEdit(categoryId);
  };

  const onDeleteCategory = async () => {
    await dispatch(deleteCategory(categoryIdToDelete));
    await handleGetAllCategories();
  };

  const handleEditArticle = (articleId, e) => {
    e.stopPropagation();
    history.push(`/admin/mrl/article/form?articleId=${articleId}`);
  };

  const onDeleteArticle = async () => {
    await dispatch(deleteArticle(articleIdToDelete));
    await handleGetAllArticles();
  };

  const handleOpenDeleteArticle = (articleId, e) => {
    e.stopPropagation();
    setDialogType(DIALOG_TYPES.deleteArticle);
    setArticleIdToDelete(articleId);
  };

  const handleDeleteArticle = () => {
    onDeleteArticle();
    setDialogType('');
    setArticleIdToDelete(null);
  };

  const handleOpenDeleteCategory = (categoryId, e) => {
    e.stopPropagation();
    setDialogType(DIALOG_TYPES.deleteCategory);
    setCategoryIdToDelete(categoryId);
  };

  const handleDeleteCategory = () => {
    onDeleteCategory();
    setDialogType('');
    setCategoryIdToDelete(null);
  };

  const toggleFiltersModal = () => {
    setIsFiltersModalOpen(!isFiltersModalOpen);
  };

  const handleSubmitFilters = (values) => {
    setFilters(values); // Update your filter state here
    handleApplyFilters(values); // Apply filters after state is updated
    toggleFiltersModal(); // Hide the modal
  };

  useEffect(() => {
    handleGetAllCategories();
    handleGetAllArticles();
  }, []);

  useEffect(() => {
    handleApplyFilters(filters);
  }, [articles, categories, filters, toggleValue]);

  return (
    <MrlLayout>
      {toggleValue === 'articles' ? (
        <ArticlesList
          filters={filters}
          createNewArticle={createNewArticle}
          toggleFiltersModal={toggleFiltersModal}
          articles={filteredArticles}
          handleEditArticle={handleEditArticle}
          handleDeleteArticle={handleOpenDeleteArticle}
          handleToggleValue={handleToggleValue}
          toggleValue={toggleValue}
        />
      ) : (
        <CategoryList
          categories={categories}
          toggleValue={toggleValue}
          filters={filters}
          handleToggleValue={handleToggleValue}
          handleEditCategory={handleEditCategory}
          handleDeleteCategory={handleOpenDeleteCategory}
          handleCategoriesModalStatus={handleCategoriesModalStatus}
        />
      )}
      <AddCategoryModal
        isModalOpen={dialogType === DIALOG_TYPES.category}
        handleClose={() => {
          handleCategoriesModalStatus();
          resetCategoryIdToEdit();
        }}
        handleSubmit={
          categoryIdToEdit
            ? (values) => onEditCategory(categoryIdToEdit, values)
            : createNewCategory
        }
        categoryData={categories.find(({ _id }) => _id === categoryIdToEdit)}
        allCategoryNames={categories.map(({ name }) => name)}
      />
      <ArticlesFilterModal
        isModalOpen={isFiltersModalOpen}
        handleSubmit={handleSubmitFilters}
        handleClose={toggleFiltersModal}
        filters={filters}
        categories={categories}
      />
      <DialogAlert
        isModalOpen={[DIALOG_TYPES.deleteArticle, DIALOG_TYPES.deleteCategory].includes(dialogType)}
        onClose={() => setDialogType('')}
        title="Confirmare stergere"
        content={dialogType === DIALOG_TYPES.deleteArticle
          ? 'Sunteti sigur ca doriti stergerea articolului?'
          : 'Sunteti sigur ca doriti stergerea categoriei?'
        }
        hasActions
        onSubmit={dialogType === DIALOG_TYPES.deleteArticle  ? handleDeleteArticle: handleDeleteCategory}
      />
    </MrlLayout>
  );
};

export default Articles;
