import { Accordion, Checkbox, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';

import { ArrowIcon } from '../../../common/components/AccordionComponent/index.styled';
import ButtonComponent from '../../../common/components/ButtonComponent';
import Iframe from '../../../common/components/Iframe';
// import {
//   ButtonText,
//   ExempleButtonContainer,
//   // TransitionTaskContainer,
// } from '../styled/MrlStyled';

import { NOT_APPLICABLE } from '../../../constants/general';

import { ArrowDown } from '../../../assets/icons';
import { NewTabIcon } from '../../../assets/icons/navbar';

const MrlTask = ({
  isDone,
  task,
  isOpen,
  onMrlTaskOpen,
  onCheckTask,
}) => {
  const userLanguage = useSelector(state => state?.userProfile?.userProfile?.language);

  return (
    <Accordion
      expanded={isOpen}
      sx={{
        boxShadow: 'none',
        '&:before': { height: '0px' },
        '&.Mui-expanded': { m: 0 },
      }}
    >
      <Box
        display="flex"
        sx={{ cursor: 'pointer' }}
        width="100%"
      >
        <Box display="flex" width="100%" gap={6} mr={10}>
          <Checkbox
            sx={{
              padding: '2px',
              height: 'fit-content',
              '& .MuiSvgIcon-root': {
                fontSize: '20px',
              },
            }}
            checked={isDone || false}
            onChange={onCheckTask}
          />
          <Typography
            variant="h3"
            fontWeight={600}
            color="primary"
            width="100%"
            onClick={() => onMrlTaskOpen(task?.name?.[userLanguage])}
          >
            {task?.name?.[userLanguage] || NOT_APPLICABLE}
          </Typography>
        </Box>

        <ArrowIcon expanded={isOpen} onClick={() => onMrlTaskOpen(task?.name?.[userLanguage])}>
          <ArrowDown size="20px" />
        </ArrowIcon>
      </Box>

      <Box pt={10}>
        <Iframe title="Preview" content={task?.description?.[userLanguage]} hasDynamicHeight />
        {task?.link && task?.buttonName?.[userLanguage] && (
          <Box display="flex" justifyContent="space-between" mt={10}>
            <a href={task?.link} target="_blank" rel="noopener noreferrer">
              <ButtonComponent
                color="secondary"
                icon={<NewTabIcon size="18px" />}
                label={task?.buttonName?.[userLanguage]}
              />
            </a>
          </Box>
        )}
      </Box>
    </Accordion>
  );
};

export default MrlTask;
