import { MenuItem, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

import FilterButton from '../../../common/components/FilterButton';
import { Loading } from '../../../common/styled';
import ChapterAccordion from '../../components/ChapterAccordion';
import DraggableWrapper from '../../components/DraggableWrapper';
import MrlLayout from '../../components/MrlLayout';
import SectionHeader from '../../components/SectionHeader';

import { useApi } from '../../../hooks/useApi';
import { getAllChapters, orderChapters } from '../../../requests/chapters';

import { COURSES_FILTER_OPTIONS } from '../../constants/options';

import { ChaptersWrapper } from './index.styled';

const Courses = () => {
  const history = useHistory();
  const location = useLocation();
  const [filter, setFilter] = useState('all');
  const [chaptersList, setChaptersList] = useState([]);
  const [{ result: chaptersListResult = [], isLoading }, fetchAllChapters] = useApi(getAllChapters);
  const [{ isLoading: isEditing }, reorderChapters] = useApi(orderChapters);

  const getIdFromUrl = (param) => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get(param);
  };

  const subchapterId = getIdFromUrl('id');
  const chapterId = getIdFromUrl('chapterId');

  const createNewChapter = () => {
    const order = chaptersList.length + 1;
    history.push(`/admin/mrl/course/form?order=${order}`);
  };

  const createNewSubchapter = (chapterId) => {
    const chapterData = chaptersList.find(
      (chapter) => chapter._id === chapterId
    );
    const order = chapterData.subchapters.length + 1;
    history.push(
      `/admin/mrl/course/form?chapterId=${chapterId}&order=${order}`
    );
  };

  const handleChaptersFetch = async () => {
    const payload =
      filter === 'visible' || filter === 'hidden'
        ? { isVisible: filter === 'visible' }
        : {};

    await fetchAllChapters(payload);
  };

  const handleReorderChapters = async (newList) => {
    const payload = newList.map((chapter) => ({
      id: chapter._id,
      order: chapter.order,
    }));

    //The setChaptersList is used to update the UI before the API call is made to avoid the UI flickering
    setChaptersList(newList);
    await reorderChapters(payload);
    await handleChaptersFetch();
  };

  const handleReorderSubchapters = async (newList) => {
    const payload = newList.map((chapter) => ({
      id: chapter._id,
      order: chapter.order,
      chapterId: chapter.chapterId,
    }));

    await reorderChapters(payload);
    await handleChaptersFetch();
  };

  const onDragEnd = async (result) => {
    const { destination, source, type } = result;

    // If there's no destination (dropped outside a droppable area) or the item is dropped in the same place, do nothing
    if (
      !destination ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index)
    ) {
      return;
    }

    // Reordering chapters
    if (type === 'CHAPTERS') {
      const newChaptersList = Array.from(chaptersList);
      const [reorderedItem] = newChaptersList.splice(source.index, 1);
      newChaptersList.splice(destination.index, 0, reorderedItem);

      // Update the order property for chapters
      newChaptersList.forEach((chapter, index) => {
        chapter.order = index + 1; // Assuming the order starts at 1
      });

      await handleReorderChapters(newChaptersList);
    } else if (type === 'SUBCHAPTER') {
      // Reordering subchapters
      const newChaptersList = Array.from(chaptersList);
      const sourceChapter = newChaptersList.find(
        (chapter) =>
          chapter._id === source.droppableId.replace('subchapters-', '')
      );
      const destChapter = newChaptersList.find(
        (chapter) =>
          chapter._id === destination.droppableId.replace('subchapters-', '')
      );
      const [reorderedSubchapter] = sourceChapter.subchapters.splice(
        source.index,
        1
      );

      // If source and destination chapters are the same, we simply reorder subchapters within the chapter
      if (source.droppableId === destination.droppableId) {
        sourceChapter.subchapters.splice(
          destination.index,
          0,
          reorderedSubchapter
        );
      } else {
        // If they're different, we transfer the subchapter to another chapter
        destChapter.subchapters.splice(
          destination.index,
          0,
          reorderedSubchapter
        );
      }

      // Update the order property for subchapters in both source and destination chapters
      sourceChapter.subchapters.forEach((sub, index) => {
        sub.order = index + 1;
      });
      destChapter.subchapters.forEach((sub, index) => {
        sub.order = index + 1;
        sub.chapterId = destChapter._id;
      });
      let newSubchaptersList = [];
      newChaptersList.forEach(
        (chapter) =>
          (newSubchaptersList = [...newSubchaptersList, ...chapter.subchapters])
      );
      await handleReorderSubchapters(newSubchaptersList);
    }
  };

  const scrollToFlickerElement = (elementId, flickerDuration = 1000) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      element.classList.add('flicker');

      // Remove flicker class after a short duration
      setTimeout(() => element.classList.remove('flicker'), flickerDuration);
    }
  };

  useEffect(() => {
    handleChaptersFetch();
  }, [filter]);

  useEffect(() => {
    setChaptersList(chaptersListResult);
  }, [chaptersListResult]);

  useEffect(() => {
    if (subchapterId) {
      scrollToFlickerElement(subchapterId);
      if (chapterId) {
        scrollToFlickerElement(chapterId);
      }
    }
  }, [subchapterId, chapterId]);

  const isDragDisabled = filter !== 'all';

  const handleSelectFilter = (value, onClose) => {
    setFilter(value);
    onClose();
  };

  return (
    <MrlLayout>
      <SectionHeader title="Module curs" onAddClick={createNewChapter}>
        <FilterButton sx={{ mr: 10 }} counter={filter !== 'all' ? 1 : 0} popperSx={{ padding: 0 }}>
          {({ onToggleFilters }) => (
            <>
              <Typography variant="body2" color="primary" fontWeight={600} py={12} px={16}>
                Vizibilitate:
              </Typography>
              {COURSES_FILTER_OPTIONS?.map(({ label, value}) => (
                <MenuItem key={value} value={value} onClick={() => handleSelectFilter(value, onToggleFilters)}>
                  {label}
                </MenuItem>
              ))}
            </>
          )}
        </FilterButton>
      </SectionHeader>
      {chaptersList.length > 0
        ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="all-chapters" type="CHAPTERS">
              {(provided) => (
                <ChaptersWrapper className="scrollbar" ref={provided.innerRef} {...provided.droppableProps}>
                  {chaptersList.map((chapter, index) => (
                    <DraggableWrapper
                      key={chapter?._id}
                      entityId={chapter?._id}
                      index={index}
                      isDragDisabled={isDragDisabled}
                      sx={{ width: '100%' }}
                    >
                      <ChapterAccordion
                        chapter={chapter}
                        createNewSubchapter={() => createNewSubchapter(chapter?._id)}
                        fetchChapters={handleChaptersFetch}
                        index={index}
                        isDragDisabled={isDragDisabled}
                      />
                    </DraggableWrapper>
                  ))}
                  {provided.placeholder}
                </ChaptersWrapper>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <Typography className="faded" mt={10}>
            Adauga un capitol facand click pe butonul din dreapta...
          </Typography>
        )}
      <Loading isLoading={isLoading || isEditing} />
    </MrlLayout>
  );
};

export default Courses;
