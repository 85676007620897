import { Box } from '@mui/material';

import ButtonComponent from '../../common/components/ButtonComponent';
import DualOptionsSelect from '../../common/components/DualOptionSelect';

import { FilterIcon } from '../../assets/icons';

import SectionHeader from './SectionHeader';

const ArticlePageHeaderSection = ({
  hasFilters,
  headerTitle,
  handleFiltersModalStatus,
  filtersNumber,
  toggleValue,
  handleToggleValue,
  onAddClick,
}) => (
  <SectionHeader title={headerTitle} onAddClick={onAddClick}>
    <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '10px' }} mr={10}>
      {hasFilters && (
        <ButtonComponent
          color="secondary"
          variant="outlined"
          onClick={handleFiltersModalStatus}
          icon={FilterIcon}
          sx={{ py: 2.75 }}
          label={`Filtre (${filtersNumber})`}
        />
      )}
      <DualOptionsSelect
        toggleValue={toggleValue}
        handleToggleValue={handleToggleValue}
      />
    </Box>
  </SectionHeader>
);

export default ArticlePageHeaderSection;
