import { styled } from '@mui/material/styles';

export const SubscriptionBox = styled('div')(({ theme, isFree }) => `
  padding: 4px;
  border-radius: 10px;
  height: 100%;
  background-image: ${isFree
  ? 'linear-gradient(to right, #3f82f3, #2b66e8)'
  : 'linear-gradient(to right, #a655f3, #9337e8)'};
  color: ${theme.palette.common.white};
  
  .MuiButton-root.Mui-disabled {
    background-color: ${theme.palette.general.accentLight3};
    color: ${theme.palette.primary.main};
  }
  
  .MuiButton-root {
    background-color: ${theme.palette.general.lightBlue4};
    color: ${theme.palette.common.white};
  }
`);

export const Description =  styled('div')(({ theme }) => `
  padding: 16px;
  background-color: ${theme.palette.common.white};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 100%;
  color: ${theme.palette.text.primary};
`);

export const FeatureSign = styled('span')(({ theme }) => `
  margin-right: ${theme.spacing(10)};
  font-weight: 700;
  font-size: 18px;
  color: ${theme.palette.success.main};
`);
