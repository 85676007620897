import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled(Box)(() =>  `
   height: 100%;
   overflow-y: auto;
   
   & form {
      width: 100%;
   }
`);
