import { styled } from '@mui/material/styles';

export const SubscriptionsTableContainer = styled('div')(({ theme }) => `
  width: 100%;
  overflow-y: scroll;
  height: 100%;
  padding: 40px;
  box-sizing: border-box;
  position: relative;
  background-color: ${theme.palette.common.white};
  border-radius: 10px;

  & table thead {
    position: sticky;
    top: 0;
    background-color: ${theme.palette.common.white};
    z-index: 1;
  }

  & table thead th {
    border-bottom: none;
    border-top: none;
    background-color: ${theme.palette.common.white};
    vertical-align: middle !important;
  }

  & table tbody td {
    border-top: none;
    border-bottom: 1px solid ${theme.palette.general.borderAccent2};
  }

  & table tbody tr {
    border-bottom: none;
    border-top: none;
  }
`);

export const Root =  styled('div')(({ theme }) => `
  padding: 20px;
  background-color: ${theme.palette.general.grayLight};
  border-radius: 5px;
  margin-top: 20px;
`);

export const FeatureSign = styled('span')(({ theme }) => `
  margin-right: ${theme.spacing(10)};
  font-weight: 700;
  font-size: 18px;
  color: ${theme.palette.success.main};
`);

export const NoFeatureSign = styled(FeatureSign)(({ theme }) => `
  color: ${theme.palette.error.main};
`);
