import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { DocumentTitle } from '../../../../common/components';
import PlatformStats from '../../../../common/components/PlatformStats';
import AuthLayout from '../../../../layouts/AuthLayout';
import { FormWrapper } from '../../../../layouts/AuthLayout/auth.styled';
import LoginForm from '../../components/LoginForm';

import { useLogin } from '../../../../hooks/useAuth';

const Login = () => {
  const intl = useIntl();
  const history = useHistory();
  const { onLogin, showResend, email } = useLogin();

  if (showResend) {
    history.push(`/account/register/verify?email=${encodeURIComponent(email)}`);
  }

  return (
    <AuthLayout>
      <DocumentTitle>{intl.formatMessage({ id: 'label.login' })}</DocumentTitle>
      <FormWrapper>
        <Box my={40}>
          <PlatformStats />
        </Box>
        <Typography variant="h2" textAlign="center" color="primary" mb={24}>
          {intl.formatMessage({ id: 'label.signIn' })}
        </Typography>
        <LoginForm onSubmit={onLogin} />
      </FormWrapper>
    </AuthLayout>
  );
};

export default Login;
