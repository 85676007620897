import { styled } from '@mui/material/styles';

export const ArrowIcon = styled(
  'div',
  { shouldForwardProp: (props) => props !== 'expanded' },
)(({ theme, expanded }) => `
  display: flex;
  height: fit-content;
  margin-top: ${theme.spacing(1)};
  transform: ${expanded ? 'rotate(180deg)' : 'rotate(0deg)'};
  
  svg > path {
    fill: ${theme.palette.primary.main};
  };
`);
