import { Box, Divider } from '@mui/material';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import HtmlEditor from '../../../common/components/HtmlEditor';
import PageTitle from '../../../common/components/PageTitle';
import { Loading } from '../../../common/styled';
import SectionTitle from '../../components/SectionTitle';

import {
  addChapter,
  editChapter,
  getChapterById,
} from '../../../requests/chapters';

import { handleApiError } from '../../../utils/errorUtils';
import { getChapterSchema } from '../../../utils/validation/chapterSchema';
import { formatCreatePayload, formatEditPayload, getInitialValues } from '../../utils/chapterUtils';

import InputField from '../../../fields/InputField';
import { Root } from './index.styled';
// import { Root } from './index.styled';

const EditChapter = () => {
  const intl = useIntl();
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const chapterId = query.get('chapterId');
  const order = +query.get('order');
  const isEdit = query.get('isEdit');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [chapter, setChapter] = useState({});
  const userLanguage = useSelector(state => state?.userProfile?.userProfile?.language);

  useEffect(() => {
    if (isEdit && chapterId) {
      fetchData();
    }
  }, [chapterId, isEdit]);

  const handleCreateNewChapter = async (values) => {
    setIsSubmitting(true);

    try {
      await addChapter(formatCreatePayload(values, chapterId, order, userLanguage));
      history.push('/admin/mrl?tab=chapters');
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEditChapter = async (values) => {
    setIsSubmitting(true);

    try {
      const updateChapterPayload = {
        params: { chapterId },
        payload: formatEditPayload(values, userLanguage),
        order,
      };
      await editChapter(updateChapterPayload);
      history.push('/admin/mrl?tab=chapters');
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);

    try {
      const response = await getChapterById(chapterId);
      setChapter(response);
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Root className="scrollbar">
      <PageTitle
        title={intl.formatMessage({ id: 'label.backToContent' })}
        onBack={history.goBack}
      />
      <Divider sx={{ borderColor: 'general.borderAccent' }} />
      <Box display="flex" justifyContent="center" mt={20} width="100%">
        <Formik
          initialValues={getInitialValues(chapter, userLanguage)}
          validationSchema={getChapterSchema(userLanguage)}
          onSubmit={isEdit ? handleEditChapter : handleCreateNewChapter}
          enableReinitialize
        >
          {({ handleSubmit, values, setFieldValue }) => (
            <form onSubmit={handleSubmit} noValidate>
              <SectionTitle
                title={intl.formatMessage({ id: 'label.chapter' })}
                isDisabled={!values.title?.[userLanguage] || !values?.description?.[userLanguage] || isSubmitting}
                sx={{ mb: 10 }}
                hasToggle
              />
              <InputField id={`title.${userLanguage}`} label={intl.formatMessage({ id: 'label.title' })} />
              <HtmlEditor
                content={values?.description?.[userLanguage]}
                onChange={(value) => setFieldValue(`description.${userLanguage}`, value)}
                topOffset="200px"
              />
            </form>
          )}
        </Formik>
      </Box>

      <Loading isLoading={isLoading} />
    </Root>
  );
};

export default EditChapter;
