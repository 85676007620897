import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

import { avatarInitials, formatUserName } from '../../../utils/formatters';
import { canInviteUsers, canManageUsers } from '../../../utils/permissions';

import MentorMenu from '../../../modules/myIdeaDescription/containers/MentorMenu';
import {
  MemberRowContainer,
  MembersCounter,
  MemberTypeContainer,
  MemberAvatar,
} from '../../containers/MemberDetails/index.styled';
import AddButton from '../AddButton';
import EmptyState from '../EmptyState';

const MentorDetails = ({
  title,
  placeholder,
  userList = [],
  openModal,
  runUpdateTeamMentors,
  loggedUser,
}) => {
  const intl = useIntl();
  const isAllowToManageUsers = canManageUsers(loggedUser);

  const getUserName = (user) => (
    `${formatUserName(user)} ${user?.isInvited ? `(${intl.formatMessage({ id: 'label.invitedAccount' })})` : ''}`
  );

  return (
    <MemberTypeContainer>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={20}
      >
        <Box display="flex" alignItems="center">
          <Typography variant="h2" color="primary">
            {title}
          </Typography>
          <MembersCounter>
            <Typography variant="body2">{userList.length || 0}</Typography>
          </MembersCounter>
        </Box>
        {canInviteUsers(loggedUser) && (
          <Box display="flex" alignItems="center">
            <AddButton onClick={openModal} color="secondary" p={6.5} />
          </Box>
        )}
      </Box>

      {userList.length ? (
        <Box display="flex" flexDirection="column">
          {userList.map((user, index) => (
            <MemberRowContainer key={index}>
              <Box display="flex">
                <MemberAvatar src={user?.profilePicture} variant="circle">
                  {avatarInitials(user)}
                </MemberAvatar>
                <Box ml={10}>
                  <Typography fontWeight={700} color="primary">
                    {getUserName(user)}
                  </Typography>
                  <Typography variant="body2" color="primary">
                    {user?.email}
                  </Typography>
                  <Typography fontWeight={600} color="general.orange">
                    {intl.formatMessage({ id: 'label.expert' })}
                  </Typography>
                </Box>
              </Box>
              {isAllowToManageUsers && (
                <Box>
                  <MentorMenu
                    member={user}
                    runUpdateTeamMentors={runUpdateTeamMentors}
                  />
                </Box>
              )}
            </MemberRowContainer>
          ))}
        </Box>
      ) : (
        <EmptyState message={placeholder} direction="flex-start" />
      )}
    </MemberTypeContainer>
  );
};

export default MentorDetails;
