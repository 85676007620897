import { filter } from 'lodash-es';

import { http } from '../core/services/http';

import {
  ADD_TEAM_MEMBER,
  ADD_TEAM_MENTOR,
  DELETE_TEAM_BY_ID,
  EDIT_TEAM_EQUITY,
  EDIT_TEAM_LOGO_ROUTE,
  EDIT_TEAM_ROUTE,
  GET_ALL_TEAMS,
  GET_TEAM_BY_ID,
  GET_TEAM_MEMBERS,
  GET_TEAM_MENTORS,
  JOIN_TEAM_COHORT,
  JOIN_TEAM_ROUTE,
  POST_NEW_TEAM,
  PUT_ACCEPT_TEAM_INVITE,
  PUT_REJECT_TEAM_INVITE,
  REMOVE_TEAM_MEMBER,
  RESEND_TEAM_MEMBER_INVITATION,
  SAVE_TEAM_INVITATION,
  TRANSFER_OWNERSHIP, UPDATE_TEAM_MRL_TASK,
} from '../constants/apiRoutes';
import { formatRoute } from '../utils/formatters';

export const addTeam = (data) => http.post(POST_NEW_TEAM, data);

export const addTeamMember = (teamId, data) => (
  http.put(formatRoute(ADD_TEAM_MEMBER,  { teamId }), data)
);

export const addTeamMentor = (teamId, data) => (
  http.put(formatRoute(ADD_TEAM_MENTOR,  { teamId }), data)
);

export const getAllTeams = (userId) => (
  http.get(formatRoute(GET_ALL_TEAMS,  { userId }))
);

export const getTeamById = (params) => http.get(formatRoute(GET_TEAM_BY_ID, params));

export const saveTeamInvitation = (teamId, data) => (
  http.put(formatRoute(SAVE_TEAM_INVITATION,  { teamId }), data)
);

export const acceptTeamInvite = (payload) => http.put(PUT_ACCEPT_TEAM_INVITE, payload);

export const rejectTeamInvite = (payload) => http.put(PUT_REJECT_TEAM_INVITE, payload);

export const deleteTeamById = (teamId, payload)  => {
  return http.post(formatRoute(DELETE_TEAM_BY_ID, { teamId }), payload);
};

export const editTeamEquity = (teamId, payload) => {
  return http.put(formatRoute(EDIT_TEAM_EQUITY, { teamId }), payload);
};

export const deleteTeamMember = (teamId, email) => {
  return http.put(formatRoute(REMOVE_TEAM_MEMBER, { teamId, email }));
};

export const resendTeamMemberInvitation = (teamId, email) => {
  return http.post(formatRoute(RESEND_TEAM_MEMBER_INVITATION, { teamId, email }));
};

export const transferOwnership = (teamId, payload) => {
  return http.put(formatRoute(TRANSFER_OWNERSHIP, { teamId }), payload);
};

export const getTeamMembers = (teamId) => {
  return http.get(formatRoute(GET_TEAM_MEMBERS, { teamId }));
};

export const getTeamMentors = (teamId) => {
  return http.get(formatRoute(GET_TEAM_MENTORS, { teamId }));
};

export const joinCohort = (teamId, payload) => {
  return http.put(formatRoute(JOIN_TEAM_COHORT, { teamId }), payload);
};

export const joinTeam = (teamCode) => {
  return http.put(JOIN_TEAM_ROUTE, { teamCode });
};

export const uploadTeamImage = (teamId, image) => {
  const formData = new FormData();
  formData.append('image', image);

  return http.put(formatRoute(EDIT_TEAM_LOGO_ROUTE, { teamId }), formData,{
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const editTeam = (teamId, data) => {
  const payload = {
    ...data,
    KPIs: filter(data.KPIs, (elem) => elem !== ''),
    foundsDestination: filter(data.foundsDestination, (elem) => elem !== ''),
  };

  return http.put(formatRoute(EDIT_TEAM_ROUTE, { teamId }), payload);
};

export const editTeamMrlTask = (values, teamId) => {
  const endpoint = formatRoute(UPDATE_TEAM_MRL_TASK, { teamId });
  return http.put(endpoint, values);
};
