import {
  faPauseCircle,
  faPlayCircle,
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import ButtonComponent from '../../../../common/components/ButtonComponent';
import FilterButton from '../../../../common/components/FilterButton';

import { currentTeamIdSelector } from '../../../../common/store';
import { loadHypotheses } from '../../../../store/hypothesis/hypothesisSlice';

import { BMC_TYPE } from '../../../../constants/bmc';
import { formatFiltersForServer } from '../../utils';

import {
  ChevronLeft,
  ChevronRight,
  FirstPageIcon,
  LastPageIcon,
} from '../../../../assets/icons';

import BmcFilterDropdown from '../BmcFilterDropdown';
import {
  BmcPlayButtonContainer,
  BmcTimelapseFilterContainer,
  InputWeek,
  BMCHistorySpan
} from './index.styled';

const BmcFilters = ({
  isPaused,
  setIsPaused,
  setAllowedProperties,
  setAllowClick,
  currentWeek,
  setCurrentWeek,
  maxWeekNumber,
  getNewWeek,
  filters,
  setFilters,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const teamId = useSelector((state) => currentTeamIdSelector(state.common.userTeams));
  const hypotheses = useSelector((state) => state.hypotheses.hypotheses) || [];
  const [customerSegments, setCustomerSegments] = useState([]);

  useEffect(() => {
    if (hypotheses?.length && !customerSegments?.length) {
      setCustomerSegments(hypotheses?.filter(({ columnBMC }) => columnBMC === BMC_TYPE.customerSegments));
    }
  }, [hypotheses]);

  const filterCount = useMemo(() => (
    Object.values({ ...filters?.importance, ...filters?.status, ...filters?.customerSegments })
      .reduce((acc, cur) => acc + cur, 0)
  ), [filters]);

  const handlePause = () => {
    if (isPaused) {
      setAllowClick(false);
    }

    setIsPaused(!isPaused);
  };

  const handleChange = (e) => {
    setCurrentWeek(e.target.value);
  };

  const handleBlur = (e) => {
    setCurrentWeek(maxWeekNumber > parseInt(e.target.value)
      ? e.target.value
      : maxWeekNumber
    );
  };

  const handleSave = (newFilters) => {
    setFilters(newFilters);
    dispatch(loadHypotheses(teamId, formatFiltersForServer(newFilters)));
  };

  return (
    <>
      <BmcTimelapseFilterContainer id="bmc-time-controls">
        <ButtonComponent
          icon={<FirstPageIcon color='currentColor' size='22px' />}
          size="small"
          variant="outlined"
          color="secondary"
          sx={{ p: 0, minWidth: 'initial' }}
          onClick={() => setAllowedProperties(1)}
        />

        <ButtonComponent
          icon={<ChevronLeft color='currentColor' size='22px' />}
          size="small"
          variant="outlined"
          color="secondary"
          sx={{ p: 0, minWidth: 'initial' }}
          onClick={() => setAllowedProperties(getNewWeek(true))}
        />

        <BMCHistorySpan>
          <Typography variant="body1" fontWeight={600}>
            {intl.formatMessage({ id: 'label.week' })}
          </Typography>
          <InputWeek
            type="number"
            value={currentWeek}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </BMCHistorySpan>

        <ButtonComponent
          icon={<ChevronRight color='currentColor' size='22px' />}
          size="small"
          variant="outlined"
          color="secondary"
          sx={{ p: 0, minWidth: 'initial' }}
          onClick={() => setAllowedProperties(getNewWeek())}
        />

        <ButtonComponent
          icon={<LastPageIcon color='currentColor' size='22px' />}
          size="small"
          variant="outlined"
          color="secondary"
          sx={{ p: 0, minWidth: 'initial' }}
          onClick={() => setAllowedProperties(maxWeekNumber)}
        />

        <BmcPlayButtonContainer>
          <FontAwesomeIcon
            icon={isPaused ? faPlayCircle : faPauseCircle}
            style={{
              color: '#FF3D00',
              fontSize: '16px',
              cursor: 'pointer',
            }}
            onClick={handlePause}
          />
        </BmcPlayButtonContainer>
      </BmcTimelapseFilterContainer>

      <FilterButton counter={filterCount} sx={{ mr: 0 }}>
        {({ onToggleFilters }) => (
          <BmcFilterDropdown
            filters={filters}
            onSave={handleSave}
            onClose={onToggleFilters}
            customerSegments={customerSegments}
          />
        )}
      </FilterButton>
    </>
  );
};

export default BmcFilters;
