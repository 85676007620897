import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';

export const getMrlTaskSchema = (userLanguage) => (
  Yup.object({
    name: Yup.object({
      [userLanguage]: Yup.string().required(<FormattedMessage id='validate.mandatoryField' />),
    }),
    description: Yup.object({
      [userLanguage]: Yup.string(),
    }),
    buttonName: Yup.object({
      [userLanguage]: Yup.string(),
    }),
    link: Yup.string(),
  })
);
