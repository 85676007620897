import { BMC_CUSTOMER_SEGMENT_COLORS } from './constants';

import {
  ContainedQuestionIcon,
  ContainedCloseIcon,
  // ContainedCheckmarkIcon
} from '../../assets/icons';

export const getNewHypothesisColor = (usedColors) => {
  const availableColors = BMC_CUSTOMER_SEGMENT_COLORS.filter(
    (color) => !usedColors.includes(color)
  );

  const randomIndex = Math.floor(Math.random() * availableColors.length);
  return availableColors[randomIndex];
};

export const getViabilityModelIcon = ({ isCompleted }) => {
  if (isCompleted) {
    return ContainedQuestionIcon;
  }

  // if (status === 'REACHED_FIRST_LEVEL') {
  //   return ContainedCheckmarkIcon;
  // }

  return ContainedCloseIcon;
};

export const getHypothesisColors = (hypothesis) => hypothesis?.segment?.map(({ color }) => color);

export const filterItemsByWeek = (items, week, startDate) => (
  items.filter((item) => getCurrentWeekForDate(new Date(item.createdAt), startDate) <= week)
);

export const getCurrentWeekForDate = (currentDate, startDate) => {
  const dayOfWeek = (7 - startDate.getDay()) * 1000 * 60 * 60 * 24;
  return +Math.ceil((currentDate - startDate - dayOfWeek) / (1000 * 60 * 60 * 24 * 7)) + 1;
};

const getLastState = (states) => {
  const lastState = { ...states[states.length - 1] };
  delete lastState.createdAt;
  return lastState;
};

export const filterAndUpdateStates = (items, week, startDate) =>
  items.map((item) => {
    if (item.states) {
      const states = filterItemsByWeek(item.states, week, startDate);
      if (states && states.length > 0) {
        return { ...item, ...getLastState(states) };
      }
    }
    return item;
  });

export const filterAndUpdateSubProperties = (items, week, startDate) => {
  const properties = ['experiments', 'resolutions', 'comments'];
  return items.map((item) => {
    for (const property of properties) {
      if (item[property]) {
        const filteredItems = filterItemsByWeek(item[property], week, startDate);
        item[property] = filterAndUpdateStates(filteredItems, week, startDate);
      }
    }
    return item;
  });
};

export const groupItemsByColumnBMC = (items) => {
  const groupedItems = {};
  for (const item of items) {
    const { columnBMC } = item;
    if (!groupedItems[columnBMC]) {
      groupedItems[columnBMC] = [item];
    } else {
      groupedItems[columnBMC].push(item);
    }
  }
  return groupedItems;
};

export const getFormattedColumns = (data) => ({
  keyPartners: {
    items: data.keyPartners || [],
  },
  channels: {
    items: data.channels || [],
  },
  costStructure: {
    items: data.costStructure || [],
  },
  customerRelationships: {
    items: data.customerRelationships || [],
  },
  customerSegments: {
    items: data.customerSegments || [],
  },
  keyActivities: {
    items: data.keyActivities || [],
  },
  keyResources: {
    items: data.keyResources || [],
  },
  revenueStreams: {
    items: data.revenueStreams || [],
  },
  valuePropositions: {
    items: data.valuePropositions || [],
  },
});

export const formatFiltersForServer = (newFilters) => {
  const importances = [];
  const hypothesisStages = [];
  const customerSegments = [];

  Object.entries(newFilters?.importance).forEach(([key, checked]) => {
    if (checked) {
      importances.push(key);
    }
  });

  Object.entries(newFilters?.status).forEach(([key, checked]) => {
    if (checked) {
      hypothesisStages.push(key);
    }
  });

  Object.entries(newFilters?.customerSegments).forEach(([key, checked]) => {
    if (checked) {
      customerSegments.push(key);
    }
  });

  return  {
    ...(importances?.length > 0 ? { importances } : {}),
    ...(hypothesisStages?.length > 0 ? { hypothesisStages } : {}),
    ...(customerSegments?.length > 0 ? { customerSegments } : {}),
  };
};
