export const COURSES_FILTER_OPTIONS = [
  {
    value: 'all',
    label: 'Toate'
  },
  {
    value: 'hidden',
    label: 'Ascunse'
  },
  {
    value: 'visible',
    label: 'Vizibile'
  },
];
