import { Box, Switch, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

import { APP_NAME } from '../../../../constants/general';

import { Root } from './index.styled';

const SubscriptionType = ({ isYearlyActive, onToggle }) => {
  const intl = useIntl();

  return (
    <Root>
      <Typography variant="h2" color="primary" mb={20}>
        {intl.formatMessage({ id: 'label.choosePlan' }, { appName: APP_NAME })}
      </Typography>
      <Typography variant="h3" mb={20}>
        {intl.formatMessage({ id: 'label.transformYourIdeas' })}
      </Typography>
      <Box display="flex" alignItems="center">
        <Typography color="primary" className={isYearlyActive && 'faded'} fontWeight={600}>
          {intl.formatMessage({ id: 'label.monthly' })}
        </Typography>

        <Switch color="secondary" checked={isYearlyActive} onClick={onToggle} />

        <Typography color="primary" className={!isYearlyActive && 'faded'} fontWeight={600}>
          {intl.formatMessage({ id: 'label.yearly' })}
        </Typography>
      </Box>
    </Root>
  );
};

export default SubscriptionType;
