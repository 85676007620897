import { styled } from '@mui/material/styles';

export const AvatarWrapper = styled('div')(() => `
  border-radius: 10px;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  img { 
    height: 100%;
    width: 100%;
    border-radius: 10px;
  }
`);

export const MyIdeaTag = styled('div')(
  ({ theme }) => `
  font-size: 14px;
  border-radius: 5px;
  padding: 5px;
  margin-right: 5px;
  color: ${theme.palette.general.purple};
  border: 1px solid ${theme.palette.general.purple};
`
);
