import { Content, Layout } from './index.styled';

const MrlLayout = ({ children }) => (
  <Layout>
    <Content>
      {children}
    </Content>
  </Layout>
);

export default MrlLayout;
