import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import TransferOwnership from '../../../modules/myIdeaDescription/components/TransferOwnership';
import DialogAlert from '../../components/DialogAlert';

import { deleteTeamMember, resendTeamMemberInvitation, transferOwnership } from '../../../requests/teams';
import { loadUserTeams } from '../../store';
import { currentTeamSelector } from '../../store/userTeamsSelector';

import { handleApiError } from '../../../utils/errorUtils';

import { ReplayIcon, ShieldPersonIcon, ThreeDotsIcon, TrashIconOutlined } from '../../../assets/icons';

const MemberMenu = ({ member, runUpdateTeamMembers }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isTransferOpen, setIsTransferOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const loggedUser = useSelector((state) => state.userProfile.userProfile);
  const currentTeam = useSelector(currentTeamSelector) || {};
  const isOwner = loggedUser?.userId?._id === currentTeam?.createdBy;
  const isMe = loggedUser?.userId?._id === member?.userId;

  const handleResendInvitation = async () => {
    try {
      setIsSubmitting(true);
      await resendTeamMemberInvitation(currentTeam._id, member.email);
      setIsDialogOpen(false);
      handleCloseMenu();
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleTransferOwnership = async () => {
    try {
      setIsSubmitting(true);
      await transferOwnership(currentTeam._id, { newOwnerId: member.userId });
      await Promise.allSettled([
        runUpdateTeamMembers(),
        dispatch(loadUserTeams(loggedUser?.userId?._id)),
      ]);
      setIsTransferOpen(false);
      handleCloseMenu();
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleOpenDelete = async () => {
    setIsDialogOpen(true);
    handleCloseMenu();
  };

  const handleDeleteMember = async () => {
    try {
      setIsSubmitting(true);
      await deleteTeamMember(currentTeam._id, member.email);
      await runUpdateTeamMembers();
      setIsDialogOpen(false);
      handleCloseMenu();
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  if (isMe || (!isOwner && !member.isInvited)) {
    return null;
  }

  return (
    <>
      <IconButton
        aria-label="more"
        id="moreButton"
        aria-controls={open ? 'moreMenu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleOpenMenu}
      >
        <ThreeDotsIcon color="white" size={18} />
      </IconButton>
      <Menu
        id="moreMenu"
        MenuListProps={{
          'aria-labelledby': 'moreButton',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
      >
        {member.isInvited && (
          <MenuItem disabled={isSubmitting} onClick={handleResendInvitation}>
            <Typography color="general.accentLight" display="flex" alignItems="center" gap={8}>
              <ReplayIcon size={18} />
              {intl.formatMessage({ id: 'button.resendInvitation' })}
            </Typography>
          </MenuItem>
        )}
        {isOwner && !isMe && (
          <MenuItem disabled={isSubmitting} onClick={() => setIsTransferOpen(true)}>
            <Typography color="general.accentLight" display="flex" alignItems="center" gap={8}>
              <ShieldPersonIcon size={18} />
              {intl.formatMessage({ id: 'button.transferOwnership' })}
            </Typography>
          </MenuItem>
        )}
        {isOwner && !isMe && (
          <MenuItem disabled={isSubmitting} onClick={handleOpenDelete}>
            <Typography color="error.secondary" display="flex" alignItems="center" gap={8}>
              <TrashIconOutlined size={18} />
              {intl.formatMessage({ id: 'button.delete' })}
            </Typography>
          </MenuItem>
        )}
      </Menu>

      {isDialogOpen && (
        <DialogAlert
          isModalOpen={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          onSubmit={handleDeleteMember}
          hasActions
          isDisabled={isSubmitting}
          title={intl.formatMessage({ id: 'modal.title.deleteMember' })}
          content={intl.formatMessage({ id: 'modal.content.deleteMember' })}
        />
      )}

      {isTransferOpen && (
        <TransferOwnership
          isOpen={isTransferOpen}
          onClose={() => setIsTransferOpen(false)}
          onSubmit={handleTransferOwnership}
        />
      )}
    </>
  );
};

export default MemberMenu;
