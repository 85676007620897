import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';

export const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) =>  `
  margin: ${theme.spacing(0, 12)};
  
  & .MuiAccordionSummary-content, .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }
`);

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) =>  `
  padding: ${theme.spacing(20)};
`);

export const Accordion = styled(
  MuiAccordion,
  { shouldForwardProp: (props) => props !== 'isVisible' },
)(({ theme, isVisible }) =>  `
  border: 1px solid ${isVisible ? theme.palette.general.accentLight : theme.palette.general.lightBlue1};
  border-radius: 6px;
  box-shadow: none;
`);
