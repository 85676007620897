import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

export const SettingsContainer = styled('div')(() => `
  display: flex;
  width: 100%;
  overflow-y: auto;
  height: 100%;
`);

export const LanguageSwitch = styled(Switch)(({ theme }) => ({
  marginLeft: 0,

  '& .MuiSwitch-track': {
    backgroundColor:  theme.palette.primary.main,

    '&:before': {
      content: '"RO"',
      color: theme.palette.common.white,
      fontSize: '13px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      width: '50%',
      position: 'absolute',
      left: 0,
      top: 0,
    },
    '&:after': {
      content: '"EN"',
      color: theme.palette.common.white,
      fontSize: '13px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      width: '50%',
      position: 'absolute',
      right: 1,
      top: 0,
    },
  },
}));
