import { Divider, Grid, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import ButtonComponent from '../../../../common/components/ButtonComponent';
import InformationRow from '../../../../common/components/InformationRow';
import LinkComponent from '../../../../common/components/LinkComponent';
import MentorDetails from '../../../../common/components/MentorDetails';
import RichTextContainer from '../../../../common/components/RichTextContainer';
import { Loading } from '../../../../common/styled';
import InfoHeader from '../../components/InfoHeader';

import { getTeamById, getTeamMembers, getTeamMentors } from '../../../../requests/teams';

import { formatDate } from '../../../../utils/dateHelpers';
import { handleApiError } from '../../../../utils/errorUtils';

import { ArrowLeft } from '../../../../assets/icons';

import MemberDetails from '../../../../common/containers/MemberDetails';
import { PaperContent } from './index.styled';

const TeamDetails = () => {
  const { teamId } = useParams();
  const intl = useIntl();
  const theme = useTheme();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [teamData, setTeamData] = useState({
    details: {},
    mentors: [],
    members: [],
  });

  useEffect(() => {
    if (teamId) {
      fetchTeamData();
    }
  }, [teamId]);

  const fetchTeamData = async () => {
    setIsLoading(true);

    try {
      const [responseTeamDetails, responseMembers, responseMentors] = await Promise.all([
        getTeamById({ teamId }), getTeamMembers(teamId), getTeamMentors(teamId)
      ]);
      setTeamData({
        details: responseTeamDetails?.data,
        members: responseMembers?.data?.filter(({ isInvited }) => !isInvited),
        mentors: responseMentors?.data?.filter(({ isInvited }) => !isInvited),
      });
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <ButtonComponent
        variant="outlined"
        onClick={history.goBack}
        icon={<ArrowLeft size={16} />}
        label={intl.formatMessage({ id: 'button.backToList' })}
        sx={{ mb: 20 }}
      />

      <InfoHeader teamDetails={teamData?.details} />

      <Divider sx={{ my: 20 }} />

      <PaperContent elevation={1} className="scrollbar">
        <Grid container justifyContent="space-between">
          <Grid item xs={12} lg={5.5}>
            <Typography variant="h2" mb={20} color="primary">
              {intl.formatMessage({ id: 'label.description' })}
            </Typography>
            {teamData?.details?.description && (
              <Box mb={20}>
                <RichTextContainer
                  text={teamData?.details?.description}
                  fontSize="14px"
                  color={theme.palette.text.primary}
                >
                  {teamData?.details?.description}
                </RichTextContainer>
              </Box>
            )}
            <InformationRow
              value={teamData?.details?.dateOfEstablishment
                ? formatDate(teamData?.details?.dateOfEstablishment)
                : null}
              label={intl.formatMessage({ id: 'label.dateOfEstablishment' })}
              placeholder={intl.formatMessage({ id: 'placeholder.noEstablishmentDate', })}
            />
            <InformationRow
              value={teamData?.details?.cui}
              sx={{ my: 8 }}
              label={intl.formatMessage({ id: 'label.personalIdentificationNumber' })}
              placeholder={intl.formatMessage({ id: 'placeholder.noPersonalIdentificationNumber' })}
            />
            <InformationRow
              sx={{ mb: 20 }}
              value={teamData?.details?.headquarters}
              label={intl.formatMessage({ id: 'label.address' })}
              placeholder={intl.formatMessage({ id: 'placeholder.noAddress' })}
            />
            <Typography className="italic" color="textSecondary">
              {intl.formatMessage({ id: 'label.website' })}
            </Typography>
            {teamData?.details?.domain
              ? <LinkComponent domain={teamData?.details?.domain} />
              : (
              <Typography className="italic" color="primary">
                {intl.formatMessage({ id: 'placeholder.noWebsite' })}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} lg={5.5}>
            <Box display="flex" flexDirection="column" gap={40}>
              <MemberDetails
                title={intl.formatMessage({ id: 'label.members' })}
                placeholder={`${intl.formatMessage({ id: 'placeholder.noMembers' })}.`}
                userList={teamData?.members}
                type="members"
              />
              <MentorDetails
                title={intl.formatMessage({ id: 'label.mentorsAndExperts' })}
                placeholder={`${intl.formatMessage({ id: 'placeholder.noMentors' })}.`}
                userList={teamData?.mentors}
              />
            </Box>
          </Grid>
        </Grid>
      </PaperContent>

      <Loading isLoading={isLoading} />
    </>
  );
};

export default TeamDetails;
