import { Formik } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import ButtonComponent from '../../../../common/components/ButtonComponent';
import { DialogActionWrapper } from '../../../../common/components/DialogComponent/index.styled';

import { joinCohort } from '../../../../requests/teams';

import { handleApiError } from '../../../../utils/errorUtils';

import { SaveIconOutlined } from '../../../../assets/icons';

import InputField from '../../../../fields/InputField';

const JoinCohortForm = ({ teamId, handleClose }) => {
  const intl = useIntl();

  const initialValues = {
    code: '',
  };

  const handleSubmit = async (values) => {
    try {
      const payload = { cohortCode: values.code };
      await joinCohort(teamId, payload);
      handleClose();
    } catch (e) {
      handleApiError(e);
    }
  };

  const schema = Yup.object().shape({
    code: Yup.string().required(
      intl.formatMessage({ id: 'validate.mandatoryField' })
    ),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <InputField
            id="code"
            label={intl.formatMessage({ id: 'label.cohortCode' })}
          />

          <DialogActionWrapper>
            <ButtonComponent
              type="submit"
              icon={<SaveIconOutlined size="16px" />}
              color="secondary"
              label={intl.formatMessage({ id: 'button.save' })}
            />
          </DialogActionWrapper>
        </form>
      )}
    </Formik>
  );
};

export default JoinCohortForm;
