import { styled } from '@mui/material/styles';

export const Root =  styled('div')(({ theme }) => `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: ${theme.palette.general.grayLight};
  border-radius: 5px;
`);
