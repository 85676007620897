import styled from 'styled-components';

export const MrlContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
`;

export const MrlHeader = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 10px;
`;

export const StepNumberContainer = styled.div`
  display: flex;
  border: 1px solid #74a3fe;
  background: white;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
`;

export const StepNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 12px;
  width: 24px;
  height: 24px;
  font-weight: 600;
  border-radius: 50%;
  background-color: ${(props) => (props.isCompleted ? 'white' : 'white')};
  border: 1px solid #b1ccfe;
  color: ${(props) => (props.isCompleted ? '#74A3FE' : '#74A3FE')};
`;

export const StepTitle = styled.h4`
  padding-top: ${(props) => props.paddingTop && props.paddingTop};
  margin-left: 2vh;
  font-weight: ${({ isCompleted }) => (isCompleted ? 'bold' : '')};
  font-size: 12px;
`;

export const MrlStepContainer = styled.div`
  overflow-y: auto;
  height: calc(100% - 80px);
  margin-top: 20px;
  list-style-position: inside;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: 20px;

  & p {
    min-height: 16px;
  }
`;

export const StepHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MarginContainer = styled.div`
  display: ${({ display }) => display && display};
  align-items: ${({ alignItems }) => alignItems && alignItems};
  justify-content: ${({ justifyContent }) => justifyContent && justifyContent};
  margin-top: ${({ marginTop }) => marginTop && marginTop};
  margin-left: ${({ marginLeft }) => marginLeft && marginLeft};
  margin-bottom: ${({ marginBottom }) => marginBottom && marginBottom};
  width: ${({ width }) => width && width};
  padding-bottom: ${({ paddingBottom }) => paddingBottom && paddingBottom};
  padding-left: ${({ paddingLeft }) => paddingLeft && paddingLeft};
  margin-right: ${({ marginRight }) => marginRight && marginRight};
  max-width: ${({ maxWidth }) => maxWidth && maxWidth};
  background-color: ${({ backgroundColor }) =>
    backgroundColor && backgroundColor};
  z-index: ${({ zIndex }) => zIndex && zIndex};
  color: ${({ color }) => color && color};
  height: ${({ height }) => height && height};
  position: ${({ position }) => position && position};
  font-size: ${({ fontSize }) => fontSize && fontSize};
`;

export const MrlSelectedStepContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  border: ${(props) => `0.5px solid ${props.theme.accentLight}`};
  border-radius: 5px;
  height: 50px;
`;

export const ExpandButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-right: 1vh;
`;

export const ExempleButtonContainer = styled.div`
  display: flex;
  border: ${(props) => `1px solid ${props.theme.blueAccent}`};
  border-radius: 5px;
  padding: 5px 10px;
  width: 7.5vw;
  justify-content: center;
  align-items: center;
  margin-bottom: 1vh;
  cursor: pointer;
`;

export const HtmlContainer = styled.span`
  color: ${(props) => props.color || 'inherit'};

  & p {
    margin-bottom: 2px;
    font-size: ${(props) => props.fontSize || 'inherit'};
  }

  & p > a {
    text-decoration: underline;
    font-size: ${(props) => props.fontSize || 'inherit'};
  }

  & ol {
    margin-left: 10px;
    font-size: ${(props) => props.fontSize || 'inherit'};
  }

  & ul > li {
    margin-bottom: 8px;
    margin-left: 10px;
    font-size: ${(props) => props.fontSize || 'inherit'};

    ::marker {
      unicode-bidi: isolate;
      font-variant-numeric: tabular-nums;
      text-transform: none;
      text-indent: 0px !important;
      text-align: start !important;
      text-align-last: start !important;
      margin-right: 0;
      content: '\\2022  ';
      font-weight: bold;
    }
  }
`;

export const ButtonText = styled.div`
  max-width: 5.5vw;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
