import { Box, Grid, Switch, Typography } from '@mui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import ChangePassword from '../../components/ChangePassword';

import { editProfile } from '../../../../store/userProfile/userProfileSlice';

import { LOCALES } from '../../../../languages/langTexts';
import { handleApiError } from '../../../../utils/errorUtils';

import DeleteAccount from '../DeleteAccount';
import { LanguageSwitch, SettingsContainer } from './index.styled';

const Settings = (props) => {
  const {
    userProfile,
    subscribeUserToMailchimp,
    unsubscribeUserFromMailchimp,
  } = props;
  const intl = useIntl();
  const dispatch = useDispatch();

  const ghidMrlStoredStatus = localStorage.getItem('SHOW_MRL_GUIDE');
  const [mrlChecked, setMrlChecked] = useState(ghidMrlStoredStatus !== 'false');
  const [isNewsletterActive, setIsNewsletterActive] = useState(userProfile?.userId?.newsletter || false);
  const [feedbackChecked, setFeedbackChecked] = useState(true);

  const handleFeedbackToggle = () => {
    setFeedbackChecked(!feedbackChecked);
  };

  const handleMrlToggle = () => {
    if (mrlChecked) {
      localStorage.setItem('SHOW_MRL_GUIDE', 'false');
      window.location.reload();
    } else {
      localStorage.setItem('SHOW_MRL_GUIDE', 'true');
      window.location.reload();
    }

    setMrlChecked(!mrlChecked);
  };

  const handleChangeLanguage = async ({ target: { checked }}) => {
    try {
      const payload = {
        ...userProfile,
        language: checked ? LOCALES.ROMANIAN : LOCALES.ENGLISH
      };
      await dispatch(editProfile(payload));
    } catch (e) {
      handleApiError(e);
    }
  };

  const handleNewsletterToggle = () => {
    if (isNewsletterActive) {
      unsubscribeUserFromMailchimp(userProfile.email);
    } else {
      subscribeUserToMailchimp(userProfile.email);
    }
    setIsNewsletterActive(!isNewsletterActive);
  };

  return (
    <SettingsContainer className="scrollbar">
      <Grid container justifyContent="space-between" spacing={80}>
        <Grid item xs={6}>
          <Typography variant="h2" mb={40} color="primary">
            {intl.formatMessage({ id: 'label.preferences' })}
          </Typography>
          <Box id="mrl-switch" sx={{ display: 'flex', alignItems: 'center' }}>
            <Switch checked={mrlChecked} onChange={handleMrlToggle} sx={{ ml: 0 }} />
            <Typography variant="h3" color="primary">
              {intl.formatMessage({ id: 'label.showMRLGuide' })}
            </Typography>
          </Box>
          <Typography variant="body1" mb={20}>
            {intl.formatMessage({ id: 'label.showMRLGuide.info' })}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Switch
              sx={{ ml: 0 }}
              checked={isNewsletterActive}
              onChange={handleNewsletterToggle}
            />
            <Typography variant="h3" color="primary">
              {intl.formatMessage({ id: 'label.newsletter' })}
            </Typography>
          </Box>
          <Typography variant="body1" mb={20}>
            {intl.formatMessage({ id: 'label.newsletter.info' })}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Switch checked={feedbackChecked} onChange={handleFeedbackToggle} sx={{ ml: 0 }} />
            <Typography variant="h3" color="primary">
              {intl.formatMessage({ id: 'label.canContactMeForFeedback' })}
            </Typography>
          </Box>
          <Typography variant="body1" mb={20}>
            {intl.formatMessage({ id: 'label.canContactMeForFeedback.info' })}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <LanguageSwitch
              checked={userProfile?.language === LOCALES.ROMANIAN}
              onChange={handleChangeLanguage}
            />
            <Typography variant="h3" color="primary">
              {intl.formatMessage({ id: 'label.preferredLanguage' })}
            </Typography>
          </Box>
          <Typography variant="body1" mb={20}>
            {intl.formatMessage({ id: 'label.language.info' })}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h2" mb={40} color="primary">
            {intl.formatMessage({ id: 'label.accountManagement' })}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: 20 }}>
            <ChangePassword/>
            <DeleteAccount />
          </Box>
        </Grid>
      </Grid>
    </SettingsContainer>
  );
};

export default Settings;
