import { Typography, Box } from '@mui/material';
import { Formik, Form } from 'formik';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import ButtonComponent from '../../../../common/components/ButtonComponent';

import { getMembersAsOptions } from '../../utils';

import AutocompleteField from '../../../../fields/AutocompleteField/index.formik';

const DeleteDetails = ({ userTeams, userId, onSubmit, isSubmitting }) => {
  const intl = useIntl();
  const ideas = useMemo(() => {
    const uniqueOwnerIdeas = [];
    const multipleOwnersIdeas = [];
    const noEquity = [];

    userTeams.forEach((team) => {
      const existingMembers = team.members?.filter(({ isInvited }) => !isInvited);

      if (team.members?.length === 1 || existingMembers?.length === 1) {
        uniqueOwnerIdeas.push(team);
      } else {
        const member = team.members.find((user) => user.userId === userId);

        if (member?.equity) {
          multipleOwnersIdeas.push(team);
        } else {
          noEquity.push(team);
        }
      }
    });

    return {
      uniqueOwnerIdeas,
      multipleOwnersIdeas,
      noEquity
    };
  }, [userTeams]);

  const initialValues = {
    ideas: ideas?.multipleOwnersIdeas?.map((idea) => ({ id: idea._id, name: idea.name, newOwner: '' })),
    uniqueOwnerIdeas: ideas?.uniqueOwnerIdeas?.map((idea) => ({ id: idea._id }))
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ values }) => (
        <Form>
          <Box>
            <Typography>
              {intl.formatMessage({ id: 'modal.content.deleteAccountDesc3' })}
            </Typography>

            {ideas?.uniqueOwnerIdeas?.length > 0 && (
              <div>
                <Typography fontWeight={700} my={15}>
                  {intl.formatMessage({ id: 'modal.content.deleteAccountDesc4' })}
                </Typography>
                {ideas?.uniqueOwnerIdeas?.map((idea) => (
                  <Typography color="primary" key={idea._id} gutterBottom>
                    {idea.name}
                  </Typography>
                ))}
              </div>
            )}

            {ideas?.noEquity?.length > 0 && (
              <div>
                <Typography fontWeight={700} my={15}>
                  {intl.formatMessage({ id: 'modal.content.deleteAccountDesc5' })}
                </Typography>
                {ideas?.noEquity?.map((idea) => (
                  <Typography color="primary" key={idea._id} gutterBottom>
                    {idea.name}
                  </Typography>
                ))}
              </div>
            )}

            {values?.ideas?.length > 0 && (
              <div>
                <Typography fontWeight={700} my={15}>
                  {intl.formatMessage({ id: 'modal.content.deleteAccountDesc6' })}
                </Typography>
                {values?.ideas?.map((idea, index) => (
                  <AutocompleteField
                    key={idea.id}
                    id={`ideas[${index}].newOwner`}
                    label={idea.name}
                    options={getMembersAsOptions(ideas?.multipleOwnersIdeas?.[index], userId)}
                  />
                ))}
              </div>
            )}
          </Box>
          <ButtonComponent
            type="submit"
            color="error"
            disabled={isSubmitting}
            label={intl.formatMessage({ id: 'button.deleteAccount' })}
          />
        </Form>
      )}
    </Formik>
  );
};

export default DeleteDetails;
