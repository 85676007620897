import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';

export const getMrlStepSchema = (userLanguage) => (
  Yup.object({
    name: Yup.object({
      [userLanguage]: Yup.string().required(<FormattedMessage id='validate.mandatoryField' />),
    }),
    description: Yup.object({
      [userLanguage]: Yup.string(),
    }),
    subtitle: Yup.object({
      [userLanguage]: Yup.string(),
    }),
  })
);
