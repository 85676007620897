import { Avatar, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useIntl } from 'react-intl';

import GradientPicture from '../../../../assets/img/gradient_picture.jpg';

import StatsSection from '../StatsSection';
import { AvatarWrapper, MyIdeaTag } from './index.styled';

const InfoHeader = ({
  teamDetails,
}) => {
  const intl = useIntl();

  return (
    <Box display="flex" justifyContent="center" px={280}>
      <Box display="flex" flexDirection="column" gap={20} width="100%">
        <Box display="flex" gap={20} alignItems={teamDetails?.ideaCategory?.length ? 'flex-end' : 'center'}>
          <AvatarWrapper>
            <Avatar
              src={teamDetails?.logo || GradientPicture}
              variant="square"
              sx={{ height: '100%', width: '100%' }}
            />
          </AvatarWrapper>

          <Box display="flex" flexDirection="column" gap={8}>
            <Typography variant="h2" color="primary">
              {teamDetails?.name}
            </Typography>
            {teamDetails?.ideaCategory && (
              <Box display="flex" alignItems="center" gap={10}>
                {teamDetails?.ideaCategory?.map((tag) => (
                  <MyIdeaTag key={tag}>{tag}</MyIdeaTag>
                ))}
              </Box>
            )}
          </Box>
        </Box>

        <Box display="flex" justifyContent="space-between" gap={24}>
          <StatsSection
            label={intl.formatMessage({ id: 'label.hypotheses' })}
            value={teamDetails?.hypothesesCount || 0}
          />
          <StatsSection
            label={intl.formatMessage({ id: 'label.activities' })}
            value={teamDetails?.activitiesCount || 0}
          />
          <StatsSection
            label={intl.formatMessage({ id: 'label.validations' })}
            value={teamDetails?.hypothesesValidatedCount || 0}
          />
          <StatsSection
            label={intl.formatMessage({ id: 'label.invalidations' })}
            value={teamDetails?.hypothesesInvalidatedCount || 0}
          />
          <StatsSection
            label={intl.formatMessage({ id: 'label.conclusions' })}
            value={teamDetails?.conclusionsCount || 0}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default InfoHeader;
