import { isEqual } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import uuid from 'react-uuid';

import OnboardingLayout from '../../modules/onboarding/components/OnboardingLayout';
import Stepper from '../../modules/onboarding/components/Stepper';

import { useApi } from '../../hooks/useApi';
import {
  getQuestionnaireProgress,
  saveQuestionAnswer,
  updateQuestionAnswer,
} from '../../requests/chestionar';
import {
  getAllSubscriptionOptions,
  initCheckoutSession,
} from '../../requests/payments';
import { addTeam, addTeamMember, addTeamMentor, getAllTeams, editTeam } from '../../requests/teams';
import { getUserProfile } from '../../requests/userProfile';
import { updateUserRole } from '../../store/userProfile/userProfileSlice';
import { editProfile } from '../../store/userProfile/userProfileSlice';

import { TEAM_ROLES, USER_ROLES } from '../../constants/roles';
import { handleApiError } from '../../utils/errorUtils';

import { Main } from '../../modules/onboarding/index.styled';
import { determinateSteps, generateQuestionData, getInitialAnswer } from '../../modules/onboarding/questions';
import { QuestionCodes } from '../../modules/onboarding/steps';

const profileSteps = [
  QuestionCodes.accountType,
  QuestionCodes.language,
  QuestionCodes.experience,
  QuestionCodes.describeYourself,
  QuestionCodes.reward,
  QuestionCodes.makeProfilePublic,
];

const OnboardingPage = () => {
  const dispatch = useDispatch();

  const [activeStep, setActiveStep] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [progress, setProgress] = useState([]);
  const [steps, setSteps] = useState([]);
  const [isInitializing, setIsInitializing] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [{ result: userProfile }, runGetLoggedUserProfile] = useApi(getUserProfile);
  const [{ result: availableSubscriptions }, runGetAllSubscriptions] = useApi(getAllSubscriptionOptions);
  const { isInvited, role } = userProfile?.userId || {};

  const stepInfo = steps[activeStep] || {};
  const QuestionComponent = stepInfo?.component;
  const hasBackButton = ![QuestionCodes.language].includes(stepInfo.questionCode);

  useEffect(() => {
    initializeQuestionnaireStep();
    runGetAllSubscriptions();
  }, [isInvited, role]);

  const initializeAnswers = (step, onboardingProgress = [], onboardingSteps = steps) => {
    const initialAnswers = getInitialAnswer(step, onboardingProgress, onboardingSteps, userProfile) || [];
    setAnswers(initialAnswers);
  };

  const initializeQuestionnaireStep = async () => {
    setIsInitializing(true);

    const { questionnaireStep = 0, userId } = await runGetLoggedUserProfile() || {};
    const onboardingProgress = await getProgress(userId?._id) || [];

    if (questionnaireStep) {
      setActiveStep(questionnaireStep);
    }

    const onboardingSteps = determinateSteps(userId?.role, userId?.isInvited);
    initializeAnswers(questionnaireStep, onboardingProgress, onboardingSteps);
    setSteps(onboardingSteps);
    setIsInitializing(false);
  };

  const getProgress = async (userId) => {
    try {
      const { data } = await getQuestionnaireProgress(userId);
      setProgress(data);
      return data;
    } catch (e) {
      toast.error('Cannot get previous progress');
    }
  };

  const sendInvitations = async () => {
    const { data } = await getAllTeams(userProfile?.userId?._id);
    const team = data[0];
    const promises = [];

    team?.membersToInvite?.forEach((member) => {
      const isMentor = member?.role === USER_ROLES.Mentor.toLowerCase();

      if (isMentor) {
        promises.push(addTeamMentor(team?._id, {
          email: member.email,
          lastName: member?.lastName,
          firstName: member?.firstName,
        }));
      } else {
        promises.push(addTeamMember(team?._id, {
          email: member.email,
          equity: member?.equity || 1,
          role: [TEAM_ROLES.Hipster],
          lastName: member?.lastName,
          firstName: member?.firstName,
        }));
      }

      ReactGA.gtag('event', 'invite_user', {
        userId: userProfile?.userId?._id,
        inviteeRole: isMentor ? USER_ROLES.Mentor : USER_ROLES.Founder,
        ...(!isMentor
          ? {
            inviteeEquity: +member?.equity || 1,
            inviteeFounderRole: [TEAM_ROLES.Hipster],
          } : {}),
      });
    });
    return Promise.allSettled(promises);
  };

  const updateUserProfile = (currentStep, values) => {
    switch (currentStep.questionCode) {
      case QuestionCodes.language:
        return dispatch(editProfile({ language: values[0] }));
      case QuestionCodes.accountType: {
        const onboardingSteps = determinateSteps(values[0], isInvited);
        setSteps(onboardingSteps);
        return dispatch(updateUserRole(values[0]));
      }
      case QuestionCodes.experience:
        return dispatch(editProfile({ expertise: values[0].expertise }));
      case QuestionCodes.describeYourself:
        return dispatch(editProfile({ ...values[0].describeYourself }));
      case QuestionCodes.reward:
        return dispatch(editProfile({ rewardPreference: values[0].rewardPreference }));
      case QuestionCodes.makeProfilePublic:
        return dispatch(editProfile({ isPublic: values[0] }));
      default:
        break;
    }
  };

  const hasChanges = () => {
    switch (stepInfo.questionCode) {
      case QuestionCodes.language:
        return userProfile.language !== answers[0];
      case QuestionCodes.accountType:
        return role !== answers[0];
      case QuestionCodes.expertise:
        return !isEqual(userProfile.expertise, answers[0].expertise);
      case QuestionCodes.describeYourself:
        return userProfile.about !== answers[0].describeYourself?.about
          || userProfile.appointmentsCalendar !== answers[0].describeYourself?.appointmentsCalendar;
      case QuestionCodes.reward:
        return !isEqual(userProfile.rewardPreference, answers[0].rewardPreference);
      case QuestionCodes.makeProfilePublic:
        return userProfile.isPublic !== answers[0];
      default:
        return true;
    }
  };

  const submitAnswers = async () => {
    if (!hasChanges()) {
      setActiveStep(activeStep + 1);
      setAnswers([]);
      initializeAnswers(activeStep + 1, progress);
      return;
    }

    try {
      const questionData = generateQuestionData({ stepInfo, step: activeStep, answers });
      setIsSubmitting(true);

      if (answers?.[0]?.createIdea) {
        if (answers[0].id) {
          const { id, ...teamDetails } = answers[0];
          await editTeam(id, teamDetails);
        } else {
          await addTeam({
            name: answers?.[0]?.name,
            description: answers?.[0]?.description,
            ideaCategory: answers?.[0]?.ideaCategory,
            stage: 'startup',
            teamCode: uuid(),
          });
        }
      }

      if (stepInfo.questionCode === QuestionCodes.subscriptionType) {
        if (role === USER_ROLES.Founder) {
          await sendInvitations();
        }
        if (answers?.[0] === 'paid') {
          const { data } = await initCheckoutSession(availableSubscriptions?.prices?.data[1]?.id);
          await saveQuestionAnswer(questionData);
          window.location.href = data.redirectUrL;
          return;
        }
      }

      const existingAnswer = progress?.find(({ questionId }) => questionId === stepInfo.questionCode);

      if (existingAnswer) {
        await updateQuestionAnswer(existingAnswer._id, questionData);
      } else {
        await saveQuestionAnswer(questionData);
      }

      if (profileSteps.includes(stepInfo.questionCode)) {
        await updateUserProfile(stepInfo, answers);
        await runGetLoggedUserProfile();
      }

      setActiveStep(activeStep + 1);
      setAnswers([]);
      getProgress(userProfile?.userId?._id);

      if (existingAnswer) {
        initializeAnswers(activeStep + 1, progress);
      }
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAnswer = (answer) => {
    const answerIsAlreadySelected = answers?.includes(answer);

    if (answerIsAlreadySelected) {
      const newAnswers = answers.filter((el) => el !== answer);
      return setAnswers(newAnswers);
    }

    if (answers.length < stepInfo?.answerLimit) {
      const newAnswers = [...answers, answer];
      return setAnswers(newAnswers);
    }

    if (answers.length === stepInfo?.answerLimit) {
      const rollOverAnswers = [...answers].slice(0, stepInfo?.answerLimit - 1);
      return setAnswers([...rollOverAnswers, answer]);
    }
  };

  const handleBack = () => {
    const newStep = activeStep - 1;

    setActiveStep(newStep);
    initializeAnswers(newStep, progress);
  };

  return (
    <OnboardingLayout isLoading={isInitializing} imageUrl={stepInfo.image}>
      <Main>
        {stepInfo?.questionCode !== QuestionCodes.confirmation && (
          <Stepper activeStep={activeStep} steps={steps} />
        )}

        {QuestionComponent && (
          <QuestionComponent
            isInvited={isInvited}
            selectedAnswers={answers}
            stepInfo={stepInfo}
            userProfile={userProfile}
            hasBackButton={hasBackButton}
            onSelect={handleAnswer}
            onBack={handleBack}
            onSubmit={submitAnswers}
            isDisabled={!answers?.length || isSubmitting}
          />
        )}
      </Main>
    </OnboardingLayout>
  );
};

export default OnboardingPage;
