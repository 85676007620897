import { http } from '../../../core/services/http';

import {
  CREATE_MRL_STEP_ROUTE,
  CREATE_MRL_TASK_ROUTE,
  DELETE_MRL_STEP_ROUTE,
  DELETE_MRL_TASK_ROUTE,
  EDIT_MRL_STEP_ROUTE,
  EDIT_MRL_TASK_ROUTE,
  GET_MRL_STEP_ROUTE,
  GET_MRL_STEPS_ROUTE,
  GET_MRL_TASK_ROUTE,
  GET_MRL_TASKS_ROUTE,
  ORDER_MRL_ROUTE,
  ORDER_MRL_STEP_ROUTE,
} from '../../../constants/apiRoutes';
import { formatRoute } from '../../../utils/formatters';

export const loadMrl = () => http.get(GET_MRL_STEPS_ROUTE);

export const editMrl = (payload, stepId) => (
  http.put(formatRoute(EDIT_MRL_STEP_ROUTE, { stepId }), payload)
);

export const getMrlById = (stepId) => (
  http.get(formatRoute(GET_MRL_STEP_ROUTE, { stepId }))
);

export const editMrlOrder = (payload) => http.put(ORDER_MRL_ROUTE, payload);

export const createMrlStep = (payload) => http.post(CREATE_MRL_STEP_ROUTE, payload);

export const deleteMrlStep = (stepId) => (
  http.delete(formatRoute(DELETE_MRL_STEP_ROUTE, { stepId }))
);

export const deleteStepTask = (stepId, taskId) => (
  http.delete(formatRoute(DELETE_MRL_TASK_ROUTE, { stepId, taskId }))
);

export const getAllMRLTasks = (stepId) => (
  http.get(formatRoute(GET_MRL_TASKS_ROUTE, { stepId }))
);

export const editTask = (stepId, taskId, payload) => (
  http.put(formatRoute(EDIT_MRL_TASK_ROUTE, { stepId, taskId }), payload)
);

export const orderMrlTasks = (stepId, payload) => (
  http.put(formatRoute(ORDER_MRL_STEP_ROUTE, { stepId }), payload)
);

export const createMrlTask = (stepId, payload) => (
  http.post(formatRoute(CREATE_MRL_TASK_ROUTE, { stepId }), payload)
);

export const getTaskDetails = (stepId, taskId) => (
  http.get(formatRoute(GET_MRL_TASK_ROUTE, { stepId, taskId }))
);
