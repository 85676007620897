import { Autocomplete, TextField } from '@mui/material';
import React from 'react';

const AutocompleteField = ({
   label,
   id,
   options,
   disabled,
   customStyles,
   onSelect,
   error,
   placeholder = '',
   getOptionLabel = (option) => option?.label || '',
   required = false,
   fullWidth = false,
   selectedOption,
 }) => {
  return (
    <Autocomplete
      disabled={disabled}
      id={id}
      options={options}
      getOptionLabel={getOptionLabel}
      value={selectedOption || null}
      sx={{ ...customStyles?.autocompleteStyles }}
      ListboxProps={{
        className: 'scrollbar',
      }}
      fullWidth={fullWidth}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          placeholder={placeholder}
          error={!!error}
          helperText={error}
          fullWidth
          required={required}
          disabled={disabled}
          classes={{
            padding: 0,
          }}
          sx={{
            ...customStyles?.textFieldStyles,
          }}
        />
      )}
      onChange={onSelect}
    />
  );
};

export default AutocompleteField;
