import { useHistory, useLocation } from 'react-router-dom';

import VerifyEmail from '../../../../common/components/VerifyEmail';
import AuthLayout from '../../../../layouts/AuthLayout';

const VerifyEmailPage = () => {
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const email = query.get('email');

  const goToLogin = () => history.push('/account/login');

  if (!email) {
    goToLogin();
  }

  return (
    <AuthLayout>
      <VerifyEmail email={email} goToLogin={goToLogin} />
    </AuthLayout>
  );
};

export default VerifyEmailPage;
