import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Layout = styled(Box)(() =>  `
   display: flex;
   align-items: center;
   flex-direction: column;
   width: 100%;
   height: 100%;
`);

export const Content = styled(Box)(({ theme }) =>  `
   display: flex;
   flex-direction: column;
   height: 100%;
   width: 75%;
   gap: ${theme.spacing(20)};
`);
