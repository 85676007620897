import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';

export const getArticleSchema = (userLanguage) => (
  Yup.object({
    title: Yup.object({
      [userLanguage]: Yup.string().required(<FormattedMessage id='validate.mandatoryField' />),
    }),
    subtitle: Yup.object({
      [userLanguage]: Yup.string(),
    }),
    description: Yup.object({
      [userLanguage]: Yup.string(),
    }),
    category: Yup.string(),
    isHidden: Yup.boolean(),
  })
);
