import { Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';

export const MemberTypeContainer = styled('div')(
  ({ theme }) => `
  color: ${theme.palette.text.secondary};
  width: 100%;
  min-width: 350px;
`);

export const MembersCounter = styled('div')(
  ({ theme }) => `
  width: 30px;
  height: 30px;
  padding: ${theme.spacing(5)};
  border-radius: 5px;
  border: 1px solid ${theme.palette.general.borderAccent};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${theme.spacing(10)};
`
);

export const MemberRowContainer = styled('div')(
  ({ theme }) => `
  display: flex;
  justify-content: space-between;
  margin-top: ${theme.spacing(10)};
  border: 1px solid ${theme.palette.general.borderAccent};
  padding: ${theme.spacing(10)};
  border-radius: 8px;
`
);

export const MemberAvatar = styled(Avatar)(
  ({ theme }) => `
  background-color: ${theme.palette.general.grayLight2};
  width: 40px;
  height: 40px;
  border-radius: 50%;
`);
