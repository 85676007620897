import {
  Divider,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import ButtonComponent from '../../../common/components/ButtonComponent';
import DialogAlert from '../../../common/components/DialogAlert';

import { useApi } from '../../../hooks/useApi';
import { deleteChapterById } from '../../../requests/chapters';

import { AddIcon, ArrowDown } from '../../../assets/icons';

import DetailsActionRow from '../DetailsActionRow';
import DraggableWrapper from '../DraggableWrapper';
import { AccordionDetails, AccordionSummary, Accordion } from './index.styled';

const ChapterAccordion = ({
  chapter,
  createNewSubchapter,
  fetchChapters,
  isDragDisabled,
}) => {
  const history = useHistory();
  const { order, isVisible, subchapters, _id: chapterId, title: chapterTitle } = chapter;
  const [isExpanded, setExpanded] = useState(false);
  const [isDeleteChapterModalOpen, setIsDeleteChapterModalOpen] = useState(false);
  const [chapterIdToDelete, setChapterIdToDelete] = useState(null);
  const userLanguage = useSelector(state => state?.userProfile?.userProfile?.language);

  const handleAccordionChange = (event, newExpanded) => {
    setExpanded(newExpanded);
  };

  const editChapter = (chapterId, order) => {
    history.push(
      `/admin/mrl/course/form?chapterId=${chapterId}&order=${order}&isEdit=true`
    );
  };

  const [, deleteChapter] = useApi(deleteChapterById);

  const handleOpen = (event, chapterId) => {
    event.stopPropagation();
    setIsDeleteChapterModalOpen(true);
    setChapterIdToDelete(chapterId);
  };

  const onDeleteChapter = async () => {
    const payload = {
      params: { chapterId: chapterIdToDelete },
    };

    await deleteChapter(payload);
    await fetchChapters();
  };

  const handleDeleteChapter = () => {
    onDeleteChapter();
    setIsDeleteChapterModalOpen(false);
    setChapterIdToDelete(null);
  };

  return (
    <Accordion expanded={isExpanded} onChange={handleAccordionChange} isVisible={isVisible}>
      <AccordionSummary expandIcon={<ArrowDown size="20px" />}>
        <DetailsActionRow
          title={chapterTitle?.[userLanguage]}
          onEdit={() => editChapter(chapterId, order)}
          onDelete={(e) => handleOpen(e, chapterId)}
          isVisible={isVisible}
          isDragDisabled={isDragDisabled}
          sx={{ mr: 8 }}
        />
      </AccordionSummary>

      <Divider />
      <AccordionDetails>
        <Typography variant="h3" color="primary">
          Subcapitole
        </Typography>

        <Droppable droppableId={`subchapters-${chapterId}`} type="SUBCHAPTER">
          {(subchaptersProvided) => (
            <div ref={subchaptersProvided.innerRef} {...subchaptersProvided.droppableProps}>
              {subchapters.length > 0 ? (
                subchapters.map((subchapter, index) => (
                  <DraggableWrapper index={index} entityId={subchapter._id} key={subchapter._id}>
                    <DetailsActionRow
                      title={subchapter?.title?.[userLanguage]}
                      isVisible={subchapter?.isVisible}
                      onEdit={() => editChapter(subchapter._id, subchapter.order)}
                      onDelete={(e) => handleOpen(e, subchapter._id)}
                    />
                    <Divider />
                  </DraggableWrapper>
                ))
              ) : (
                <Typography className="faded" mt={20}>
                  Adauga un subcapitol facand click pe butonul de mai jos...
                </Typography>
              )}

              {subchaptersProvided.placeholder}
            </div>
          )}
        </Droppable>

        <ButtonComponent
          color="secondary"
          onClick={createNewSubchapter}
          icon={<AddIcon fill='currentColor' width='22px' height='22px' />}
          label="Adauga un subcapitol"
          sx={{ mt: 20 }}
        />
      </AccordionDetails>

      <DialogAlert
        isModalOpen={isDeleteChapterModalOpen}
        onClose={() => setIsDeleteChapterModalOpen(false)}
        title="Confirmare stergere"
        content="Sunteti sigur ca doriti stergerea capitolului?"
        hasActions
        onSubmit={handleDeleteChapter}
      />
    </Accordion>
  );
};

export default ChapterAccordion;
