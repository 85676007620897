import { Box, IconButton, Typography, useTheme } from '@mui/material';

import { DragIndicator, EditIcon, TrashIconOutlined } from '../../../assets/icons';

const DetailsActionRow = ({
  onEdit,
  onDelete,
  title,
  isVisible,
  isDragDisabled,
  sx = {},
}) => {
  const theme = useTheme();

  return (
    <Box display="flex" gap={15} alignItems="center" justifyContent="space-between" width="100%" sx={sx}>
      <Box display='flex' alignItems="center" gap={15} py={20}>
        {!isDragDisabled && <DragIndicator fill={theme.palette.general.accentLight} />}
        <Typography variant="body1" className={!isVisible ? 'faded' : ''}>
          {title}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" gap={5}>
        <IconButton color="secondary" onClick={onEdit}>
          <EditIcon size="16px" />
        </IconButton>
        <IconButton color="error" onClick={onDelete}>
          <TrashIconOutlined size="18px" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default DetailsActionRow;
