import { Box, Divider } from '@mui/material';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import HtmlEditor from '../../../common/components/HtmlEditor';
import PageTitle from '../../../common/components/PageTitle';
import { Loading } from '../../../common/styled';
import SectionTitle from '../../components/SectionTitle';
import { StageTitle } from '../../styled/MrlStyled';

import {
  createMrlStep,
  editMrl,
  getMrlById,
} from '../../../modules/mrl/services/mrl';

import { handleApiError } from '../../../utils/errorUtils';
import { getMrlStepSchema } from '../../../utils/validation/mrlStepSchema';
import { getInitialValues } from '../../utils/stepUtils';

import { Root } from './index.styled';

const EditMrlStep = () => {
  const intl = useIntl();
  const history = useHistory();
  const { stepId } = useParams();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState({});
  const userLanguage = useSelector(state => state?.userProfile?.userProfile?.language);

  useEffect(() => {
    if (stepId) {
      fetchStep();
    }
  }, [stepId]);

  const handleRedirect = () => history.push('/admin/mrl?tab=mrlSteps');

  const handleCreate = async (values) => {
    setIsSubmitting(true);

    try {
      await createMrlStep(values);
      handleRedirect();
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEdit = async (values) => {
    setIsSubmitting(true);

    try {
      await editMrl(values, stepId);
      handleRedirect();
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const fetchStep = async () => {
    setIsLoading(true);

    try {
      const response = await getMrlById(stepId);
      setStep(response?.data);
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = ({ target: { value, name }}, onSetFieldValue) => {
    onSetFieldValue(name, value);
  };

  return (
    <Root className="scrollbar">
      <PageTitle
        title={intl.formatMessage({ id: 'label.backToMRL' })}
        onBack={history.goBack}
      />
      <Divider sx={{ borderColor: 'general.borderAccent' }} />
      <Box display="flex" justifyContent="center" mt={20} width="100%">
        <Formik
          initialValues={getInitialValues(step, userLanguage)}
          validationSchema={getMrlStepSchema(userLanguage)}
          onSubmit={stepId ? handleEdit : handleCreate}
          enableReinitialize
        >
          {({ handleSubmit, values, setFieldValue }) => (
            <form onSubmit={handleSubmit} noValidate>
              <SectionTitle
                title={intl.formatMessage({ id: 'label.step' })}
                isDisabled={!values.name?.[userLanguage] || isSubmitting}
                sx={{ mb: 10 }}
              />
              <StageTitle
                placeholder={intl.formatMessage({ id: 'label.stepName' })}
                name={`name.${userLanguage}`}
                value={values?.name?.[userLanguage]}
                onChange={(e) => handleChange(e, setFieldValue)}
              />
              <StageTitle
                placeholder={intl.formatMessage({ id: 'label.subtitle' })}
                name={`subtitle.${userLanguage}`}
                value={values?.subtitle?.[userLanguage]}
                onChange={(e) => handleChange(e, setFieldValue)}
              />
              <HtmlEditor
                content={values?.description?.[userLanguage]}
                onChange={(value) => setFieldValue(`description.${userLanguage}`, value)}
                topOffset="200px"
              />
            </form>
          )}
        </Formik>
      </Box>

      <Loading isLoading={isLoading || isSubmitting} />
    </Root>
  );
};

export default EditMrlStep;
