import { createGlobalStyle } from 'styled-components';

import fontDosis from '../fonts/Dosis-Regular.ttf';
import fontBold from '../fonts/OpenSans-Bold.ttf';
import fontLight from '../fonts/OpenSans-Light.ttf';
import fontRegular from '../fonts/OpenSans-Regular.ttf';
import fontSemiBold from '../fonts/OpenSans-SemiBold.ttf';
import fontRaleway from '../fonts/Raleway-Regular.ttf';

const GlobalStyle = createGlobalStyle`

@font-face {
  font-family: 'Open Sans';
  src: url(${fontLight});
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src:
    url(${fontRegular});
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Dosis';
  src:
    url(${fontDosis});
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url(${fontSemiBold});
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src:  url(${fontBold});
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src:  url(${fontRaleway});
  font-weight: 500;
  font-style: normal;
}

body { 
  font-family: "Open Sans", sans-serif;
}

.richEditor-align-left  > div {
  text-align: left 
}

.richEditor-align-center > div {
  text-align: center 
}

.richEditor-align-right > div {
  text-align: right 
}

.richEditor-align-justify > div {
  text-align: justify 
}

.rich-editor-wrapper {
  overflow-y: hidden;
  height: 100%;
}

.rich-editor{
  padding: 10px;
}

.comments-editor{
  height: 210px;
  padding: 10px;
}

.nav-tooltip {
  border-radius:  5px !important;
  padding: 8px !important;
  background-color: #131336 !important;
  color: #F4F9FA !important;
}

.clamped-tooltip {
  background-color: #131336 !important;
  color: #F4F9FA !important;
  max-width: 200px !important;
}

.bmc-icon {
  fill: white;
}

.side-nav-item{
  margin-bottom: 5px;
  
  .icon-container, .nav-profile-container {
      :hover{
        background-color: #74A3FE;
        > svg {
          fill: white;
        }
      }
  }
}

.disabledNavItem  {
  opacity: 0.5;
}

@keyframes flickerAnimation {
  0%   { opacity: 1; }
  50%  { opacity: 0.5; }
  100% { opacity: 1; }
}

.flicker {
  animation: flickerAnimation 1s infinite;
}

.dropdown-toggle::after {
    display: none;
}

.scrollbar::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}

.scrollbar::-webkit-scrollbar-track {
    background-color: transparent;
    border: solid 4px transparent;
}

.scrollbar::-webkit-scrollbar-thumb {
    background-color: #74A3FE;
    border-radius: 6px;
    background-clip: padding-box;
    border: solid 4px transparent;
    border-top: 0;
    border-bottom: 0;
}

.hidden-scroll {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
    overflow-x: hidden;
}

.hidden-scroll::-webkit-scrollbar {
    width: 0;
    height: 0;
}

* {
  box-sizing: border-box;
}


.input-with-button{
  > div {
    padding-bottom: 40px;
  }
}

.rich-text-editor{
    cursor: text;
    position: relative;
}

.rdw-link-modal-target-option {
  margin-bottom: 0;
}

.rdw-link-modal{
  padding: 5px;
  & > input {
    margin-bottom: 10px;
  }
  
}

.text-right{
    text-align: right;
}

.default-border{
    border: 1px solid #2A5AB7
}

.display-flex{
    display: flex;
}

.cursor-pointer{
    cursor: pointer;
}

.align-items-center{
    align-items: center;
}

.justify-content-space-between{
    justify-content: space-between;
}


.justify-content-center{
    justify-content: center;
}

.flex-column{
    flex-direction: column;
}

.no-select {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


.toolbar-option, .toolbar-option .rdw-option-wrapper {
  border: none;
}

  ul, ol {
    margin: 0;
    padding: 0;
  }

  textarea:focus {
    outline: none !important;
  }

  button,
  [type='button'],
  [type='reset'],
  [type='submit'] {
    -webkit-appearance: none;
  }

  /* Toastify Styles */
  .toastify {
    padding: .75rem 1.25rem !important;
    margin-bottom: 1rem !important;
    border: 1px solid transparent !important;
    border-radius: .25rem !important;

    .toastify-body {
      padding: 0 !important;
      margin: 0 !important;
      align-self: center;
    }

    &.toastify-type-success {
      color: #155724 !important;
      background-color: #d4edda !important;
      border-color: #c3e6cb !important;

      .toastify-progress {
        background-color: #809379 !important;
      }
    }

    &.toastify-type-warning  {
      color: #856404 !important;
      background-color: #fff3cd !important;
      border-color: #ffeeba !important;

      .toastify-progress {
        background-color: #c09f74 !important;
      }
    }

    &.toastify-type-error {
      color: #721c24 !important;
      background-color: #f8d7da !important;
      border-color: #f5c6cb !important;

      .toastify-progress {
        background-color: #b5787c !important;
      }
    }
  }
  
  /* Bootstrap Theme */
  .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #8190a0;
  }

  /* Overwrite modals width */
  .modal-dialog {
    max-width: 900px;
    margin: 1.75rem auto;
    padding-left: 150px;
    padding-right: 150px;
  }

  .react-datepicker__input-container {
    width: 100%;
  }

  .modal-header .close {
    display:none;
  }

  textarea {
    resize: none;
  }

  .text-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export default GlobalStyle;
