import { Accordion, Box, Typography } from '@mui/material';
import React, { useState } from 'react';

import { ArrowDown } from '../../../assets/icons';

import { ArrowIcon } from './index.styled';

const AccordionComponent = ({
  title,
  children,
  actions,
  pl = 24,
  variant = 'body2',
  arrowPlacement = 'start',
  fullWidth = false,
}) => {
  const [accordionOpen, setAccordionOpen] = useState(true);

  const toggleTasksAccordion = () => setAccordionOpen(!accordionOpen);

  return (
    <Accordion
      expanded={accordionOpen}
      sx={{
        boxShadow: 'none',
        '&:before': { height: '0px' },
        '&.Mui-expanded': {
          mt: 0,
        },
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          display="flex"
          gap={10}
          sx={{ cursor: 'pointer' }}
          width={fullWidth ? '100%' : 'initial'}
          onClick={toggleTasksAccordion}
        >
          {arrowPlacement === 'start' && (
            <ArrowIcon expanded={accordionOpen}>
              <ArrowDown size="20px" />
            </ArrowIcon>
          )}

          <Typography
            width={fullWidth ? '100%' : 'initial'}
            variant={variant}
            fontWeight={600}
            color="primary"
          >
            {title}
          </Typography>

          {arrowPlacement === 'end' && (
            <ArrowIcon expanded={accordionOpen}>
              <ArrowDown size="20px" />
            </ArrowIcon>
          )}
        </Box>

        {actions}
      </Box>

      <Box pl={pl} pt={10}>
        {children}
      </Box>
    </Accordion>
  );
};

export default AccordionComponent;
