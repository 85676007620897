import { SearchPersonIcon, ThermostatIcon } from '../../assets/icons';

export const MRL_TABS = [
  {
    key: 'mrlSteps',
    label: 'label.guideSteps',
    icon: <ThermostatIcon />,
  },
  {
    key: 'articles',
    label: 'label.articles',
    icon: <SearchPersonIcon />,
  },
  {
    key: 'chapters',
    label: 'label.courses',
    icon: <ThermostatIcon />,
  },
];
