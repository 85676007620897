import {
  Typography, MenuItem, Box,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { isAdminSelector } from '../../../../store/auth/login';

import { LogoutIcon, SettingsIcon } from '../../../../assets/icons';

import { LogoutWrapper, Menu } from './index.styled';

const UserMenu = (props) => {
  const {
    anchorEl, sx = {}, onClose, onProfileRedirect, onLogout,
  } = props;
  const open = Boolean(anchorEl);
  const intl = useIntl();
  const isAdmin = useSelector((state) => isAdminSelector(state?.account));

  return (
    <Menu
      id="user-menu"
      aria-labelledby="demo-positioned-button"
      anchorEl={anchorEl}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      sx={sx}
      keepMounted
      open={open}
      onClose={onClose}
    >
      {!isAdmin && (
        <MenuItem onClick={onProfileRedirect}>
          <Box display="flex" alignItems="center" gap={8} ml={1}>
            <SettingsIcon width="19px" height="19px" />
            <Typography variant="body1">
              {intl.formatMessage({ id: 'label.accountSettings' })}
            </Typography>
          </Box>
        </MenuItem>
      )}
      <MenuItem onClick={onLogout}>
        <LogoutWrapper>
          <LogoutIcon size={22} />
          <Typography variant="body1">
            {intl.formatMessage({ id: 'button.logout' })}
          </Typography>
        </LogoutWrapper>
      </MenuItem>
    </Menu>
  );
};

export default UserMenu;
