import { http } from '../core/services/http';

import { ACCOUNT_PASSWORD_ROUTE, GET_INVITED_MEMBER_DATA, POST_SIGN_UP_INVITED_MEMBER } from '../constants/apiRoutes';
import { formatRoute } from '../utils/formatters';
import { createSession, getSessionValue, destroySession } from '../utils/session';
import transformLogin from '../utils/transformLogin';

export const isEmailRegistered = (email) =>
  http.post('account/is-email-registered', { email });

export const register = async (data) => {
  const response = await http.post('account/sign-up', data);
  const user = transformLogin(response.data);
  return user;
};

export const login = async (email, password) => {
  const response = await http.post('account/login', { email, password });
  const user = transformLogin(response.data);
  createSession(response.headers.authtoken, user);
  return user;
};

export const getAuthToken = () => getSessionValue('authToken');
export const getUser = () => getSessionValue('user');

export const logout = () => {
  destroySession();
};

export const requestPasswordReset = (email) =>
  http.post(`/account/create-password-reset-token/${email}`);

export const resetPassword = (token, email, password) =>
  http.put(`/account/reset-password?passwordResetToken=${token}`, {
    email,
    password,
  });

export const findConfirmationToken = (token) =>
  http.get(`account-confirmation/get/${token}`);

export const resendConfirmationToken = (token) =>
  http.post(`account-confirmation/resend-email/${token}`);

export const resendConfirmationEmail = (email) =>
  http.post(`account-confirmation/resend-email/${email}`);

export const changePassword = (payload) => {
  return http.post(ACCOUNT_PASSWORD_ROUTE, payload);
};

export const getInvitedMemberData = (params) => http.get(formatRoute(GET_INVITED_MEMBER_DATA, params));

export const postRegisterInvitedMember = (payload) => (
  http.post(POST_SIGN_UP_INVITED_MEMBER, payload)
);
