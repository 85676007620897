import { Box, InputAdornment, TextField, useTheme, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import RichTextContainer from '../../../common/components/RichTextContainer';

import { SearchPersonIcon, SearchIcon } from '../../../assets/icons';

const ArticlesSideGuide = ({ articlesListResult, handleArticleClick }) => {
  const intl = useIntl();
  const [searchTerm, setSearchTerm] = useState('');
  const theme = useTheme();
  const userLanguage = useSelector(state => state?.userProfile?.userProfile?.language);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filterByTitle = (arrayOfObjects, searchTerm) =>
    arrayOfObjects.filter((item) =>
      item.title?.[userLanguage]?.toLowerCase()?.includes(searchTerm.toLowerCase())
    );

  const filteredResults = articlesListResult?.length
    ? filterByTitle(articlesListResult, searchTerm)
    : [];

  return (
    <>
      <Box sx={{ display: 'flex', gap: '10px' }}>
        <SearchPersonIcon fill={theme.palette.primary.secondary} />
        <Typography color="primary">{intl.formatMessage({ id: 'label.articles' })}</Typography>
      </Box>
      <Box
        className="scrollbar"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          marginTop: '5px',
          overflowY: 'auto',
          height: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            marginTop: '15px',
          }}
        >
          <TextField
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder={intl.formatMessage({ id: 'placeholder.searchArticles' })}
            variant="outlined"
            fullWidth
            sx={{
              '& .MuiOutlinedInput-root': {
                height: '44px',
                borderRadius: '6px',
                '&.Mui-focused fieldset': {
                  borderColor: 'primary.main',
                },
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ cursor: 'pointer' }}>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}
        >
          {!!filteredResults?.length &&
            filteredResults.map((article) => (
              <Box
                onClick={() => handleArticleClick(article._id)}
                key={article._id}
                sx={{
                  padding: '10px 0',
                  borderBottom: '1px solid #74A3FE',
                  cursor: 'pointer',
                }}
              >
                <Typography variant="h3" color="primary">{article.title?.[userLanguage]}</Typography>
                <Box
                  sx={{
                    maxHeight: '110px',
                    overflow: 'hidden',
                    position: 'relative',
                    '&:after': {
                      content: '""',
                      position: 'absolute',
                      bottom: 0,
                      right: 0,
                      width: '100%',
                      height: '40px',
                      background:
                        'linear-gradient(to bottom, transparent, white 90%)',
                    },
                  }}
                >
                  <RichTextContainer text={article.description?.[userLanguage]} />
                </Box>
              </Box>
            ))}
        </Box>
      </Box>
    </>
  );
};

export default ArticlesSideGuide;
