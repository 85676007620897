import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { WhiteCard } from '../../../../common/styled';
import ResetPasswordForm from '../../components/ResetPasswordForm';

import { useLogin } from '../../../../hooks/useAuth';
import { resetPassword } from '../../../../requests/auth';

import { handleApiError } from '../../../../utils/errorUtils';

import { AccountPage, AccountPanel } from '../../index.styled';

const ResetPassword = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { onLogin, email: userEmail, showResend } = useLogin();
  const location = useLocation();

  const onSubmit = async ({ password }) => {
    try {
      setIsSubmitting(true);
      const queryParams = new URLSearchParams(location.search);
      const token = queryParams.get('token');
      const email = queryParams.get('email');
      await resetPassword(token, email, password);
      await onLogin({ email, password }, { setSubmitting: setIsSubmitting });
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (showResend) {
    history.push(`/account/register/verify?email=${encodeURIComponent(userEmail)}`);
  }

  return (
    <AccountPage>
      <AccountPanel isLoading={isSubmitting}>
        <WhiteCard noOverflow padding="30px" borderRadius="10px">
          <ResetPasswordForm handleSubmit={onSubmit} />
        </WhiteCard>
      </AccountPanel>
    </AccountPage>
  );
};

export default ResetPassword;
