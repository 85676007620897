import { Box } from '@mui/material';
import { Draggable } from 'react-beautiful-dnd';

const DraggableWrapper = ({
  entityId,
  index,
  children,
  isDragDisabled = false,
  sx = {},
}) => (
  <Draggable draggableId={entityId} index={index} isDragDisabled={isDragDisabled}>
    {(provider) => (
      <Box
        ref={provider.innerRef}
        {...provider.draggableProps}
        {...provider.dragHandleProps}
        id={entityId}
        sx={sx}
      >
        {children}
      </Box>
    )}
  </Draggable>
);

export default DraggableWrapper;
