import { Grid } from '@mui/material';
import { useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  cancelSubscription,
  initCheckoutSession,
  updateSubscription
} from '../../../../requests/payments';

import { handleApiError } from '../../../../utils/errorUtils';
import { formatSubscriptionPrice } from '../../../../utils/formatters';

import SubscriptionCard from '../SubscriptionCard';
import { Root } from './index.styled';

const PlanOptions = ({ isYearlyActive }) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const currentSubscription = useSelector((state) => state?.userProfile?.userProfile?.userId?.subscription) || {};
  const userProfile = useSelector((state) => state?.userProfile?.userProfile) || {};
  const subscriptionProducts = useSelector((state) => state.userProfile?.allSubscriptionProducts) || {};

  const currentSubscriptionPrice = formatSubscriptionPrice(currentSubscription?.amountDecimal);
  const activeSubscriptionChoices = subscriptionProducts[isYearlyActive ? 'yearly' : 'monthly'] || [];
  const subscriptions = useMemo(() => ([
    {
      isFree: true,
      name: intl.formatMessage({ id: 'label.explorer' }),
      description: intl.formatMessage({ id: 'label.expertPlanDescription' }),
      price: intl.formatMessage({ id: 'label.free' }),
      features: [
        intl.formatMessage({ id: 'label.oneActiveIdea' }),
        intl.formatMessage({ id: 'label.unlimitedCollaborators' }),
        intl.formatMessage({ id: 'label.oneExpertOrMentor' }),
        intl.formatMessage({ id: 'label.accessToBasicTools' }),
        intl.formatMessage({ id: 'label.communityParticipation' }),
      ],
    },
    ...(activeSubscriptionChoices?.[0] ? [
      {
        ...activeSubscriptionChoices[0],
        description: intl.formatMessage({ id: 'label.premiumPlanDescription' }),
        features: [
          intl.formatMessage({ id: 'label.unlimitedIdeas' }),
          intl.formatMessage({ id: 'label.unlimitedCollaboratorsAndExperts' }),
          intl.formatMessage({ id: 'label.accessToAdvancedTools' }),
          intl.formatMessage({ id: 'label.participationInAcceleratorEvents' }),
          intl.formatMessage({ id: 'label.prioritySupportAndMentoring' }),
        ],
      }
    ] : []),
  ]), [activeSubscriptionChoices]);

  const handleUpgrade = async (item) => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      if (currentSubscription?.stripeSubscriptionId && currentSubscription?.stripeCustomerId) {
        await updateSubscription({ planId: item.priceId });
        ReactGA.gtag('event', 'change_subscription', {
          userId: userProfile?.userId?._id,
          type: 'subscription',
          value: item?.price,
          currency: item.currency,
          items: [item],
          previousAmount: currentSubscriptionPrice,
          previousRecurrence: currentSubscription.planRecurringPeriod,
        });
        window.location.reload();
      } else {
        const { data } = await initCheckoutSession(item.priceId);

        ReactGA.gtag('event', 'purchase', {
          userId: userProfile?.userId?._id,
          type: 'subscription',
          value: item?.price,
          currency: item.currency,
          items: [item],
        });

        if (data.redirectUrL) {
          window.location.href = data.redirectUrL;
        }
      }
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelSubscription = async () => {
    if (!currentSubscriptionPrice) {
      return;
    }

    try {
      setIsLoading(true);
      await cancelSubscription();
      ReactGA.gtag('event', 'unsubscribe', {
        userId: userProfile?.userId?._id,
        previousAmount: currentSubscriptionPrice,
        previousRecurrence: currentSubscription.planRecurringPeriod,
      });
      window.location.reload();
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Root>
      <Grid container spacing={16}>
        {subscriptions?.map((product, index) => (
          <Grid item xs={6} key={`${product.name}-${index}`}>
            <SubscriptionCard
              product={product}
              isYearlyActive={isYearlyActive}
              isLoading={isLoading}
              currentSubscription={currentSubscription}
              onUpgrade={handleUpgrade}
              onCancelSubscription={handleCancelSubscription}
            />
          </Grid>
        ))}
      </Grid>
    </Root>
  );
};

export default PlanOptions;
