import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';

import { SIDEBAR_WIDTH } from '../../../constants/general';

export const ImageIconRound = styled('img')(({ withBorder, theme }) =>`
  width: 42px;
  height: 42px;
  border-radius: 50%;
  object-fit: cover;
  border: ${withBorder ? `1px solid ${theme.palette.general.purple}` : 'none'};
`);

export const NavigationBarIconContainer = styled('div')(({ theme}) => `
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: ${theme.spacing(6)};
  align-items: center;
  border-radius: 4px;
  width: 100%;
  max-width: calc(${SIDEBAR_WIDTH}px - 8px);
  padding: ${theme.spacing(6, 16)};
  
  & svg {
    height: 22px;
    width: 22px;
  }
  
  & .MuiTypography-root {
    color: ${theme.palette.common.white};
  }
`);

export const Content = styled('ul')(({ theme }) => `
  display: flex;
  flex-direction: column;
  width: fit-content;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: ${theme.palette.primary.main};
  height: 100vh;
  width: ${SIDEBAR_WIDTH}px;
  padding: ${theme.spacing(0, 4, 15)};
  
  li {
    margin: 4px 0;
  }
  
  a {
   width: 100%;
  }
`);

export const Option = styled('li')(({ theme }) =>  `
  border-radius: 5px;
        
  &:hover {
     background-color: ${theme.palette.general.accentLight};
  }
`);

export const AppLink = styled(NavLink)(({ theme }) =>  `
  display: flex;
  justify-content: center;
  cursor: pointer;
  padding: ${theme.spacing(18, 0)};
`);
