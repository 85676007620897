import { styled } from '@mui/material/styles';

export const SearchWrapper = styled('div')(({ theme }) => `
  display: flex;
  gap: ${theme.spacing(16)};
  align-items: center;
  margin-bottom: ${theme.spacing(20)};
  width: 60%;

   ${theme.breakpoints.down('md')} {
     width: 100%;
  };
`);
