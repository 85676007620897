import { Box, IconButton } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

import { experienceOptions } from '../../constants/options';

import { TrashIconOutlined } from '../../assets/icons';

import AutocompleteField from '../../fields/AutocompleteField/index.formik';
import InputField from '../../fields/InputField';

const ExperienceRow = ({ position, values, setValues, sx }) => {
  const intl = useIntl();

  const deleteExperience = () => {
    values.expertise.splice(position, 1);
    setValues(values);
  };

  const renderOptionLabel = (option) => intl.formatMessage({ id: option.label });

  return (
    <Box sx={sx}>
      <Box display="flex" alignItems="center" gap={16} mb={8}>
        <AutocompleteField
          id={`expertise[${position}].title`}
          label={intl.formatMessage({ id: 'label.expertise' })}
          getOptionLabel={renderOptionLabel}
          fullWidth
          sx={{ flexGrow: 1, mb: 15 }}
          options={experienceOptions}
        />
        <InputField
          id={`expertise[${position}].years`}
          label={intl.formatMessage({ id: 'label.yearsOfExperience' })}
        />
        {values.expertise?.length > 1 && (
          <IconButton sx={{ mb: 15 }} color="error" onClick={deleteExperience}>
            <TrashIconOutlined />
          </IconButton>
        )}
      </Box>

      <InputField
        multiline
        rows={4}
        id={`expertise[${position}].description`}
        label={intl.formatMessage({ id: 'label.describeYourExperience' })}
      />
    </Box>
  );
};

export default ExperienceRow;
