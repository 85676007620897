import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TasksWrapper = styled(Box)(({ theme }) =>  `
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: ${theme.spacing(10)};
  overflow-y: auto;
  width: 100%;
  height: calc(100% - 93px);
`);
