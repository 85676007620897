import { Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { Loading } from '../../../common/styled';
import DraggableWrapper from '../../components/DraggableWrapper';
import MrlLayout from '../../components/MrlLayout';
import SectionHeader from '../../components/SectionHeader';
import StepAccordion from '../../components/StepAccordion';

import { loadMrl, orderMrlTasks, editMrlOrder } from '../../../modules/mrl/services/mrl';

import { handleApiError } from '../../../utils/errorUtils';
import { getReorderedSteps, getReorderedTasks } from '../../utils/mrlUtils';

import { TasksWrapper } from './index.styled';

const MrlGuide = () => {
  const intl = useIntl();
  const history = useHistory();
  const [steps, setSteps] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    fetchMrl();
  }, []);

  const fetchMrl = async () => {
    setIsLoading(true);

    try {
      const response = await loadMrl();
      setSteps(response?.data?.sort((a, b) => a.order - b.order));
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const onDragEnd = async (result) => {
    setIsSubmitting(true);

    try {
      if (result?.type === 'STEPS') {
        const reorderedItems = getReorderedSteps(result, steps);
        await editMrlOrder(reorderedItems);
      }

      if (result?.type === 'TASKS') {
        const { sourceItems, destItems } = getReorderedTasks(result, steps);
        const promises = [orderMrlTasks(result?.source?.droppableId, sourceItems)];

        if (destItems) {
          promises.push(orderMrlTasks(result?.destination?.droppableId, destItems));
        }

        await Promise.all(promises);
      }

      fetchMrl();
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCreateMRL = () => history.push('/admin/mrl/step/create');

  return (
    <MrlLayout>
      <SectionHeader
        title={intl.formatMessage({ id: 'label.mrlGuide' })}
        onAddClick={handleCreateMRL}
      />

      {steps?.length > 0
        ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="mrl" type="STEPS">
              {(provided) => (
                <TasksWrapper className="scrollbar" ref={provided.innerRef} {...provided.droppableProps}>
                  {steps.map((step, index) => (
                    <DraggableWrapper
                      key={step._id}
                      entityId={step._id}
                      index={index}
                      sx={{ width: '100%' }}
                    >
                      <StepAccordion step={step} onFetchSteps={fetchMrl} />
                    </DraggableWrapper>
                  ))}
                  {provided.placeholder}
                </TasksWrapper>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <Typography className="faded" mt={10}>
            {intl.formatMessage({ id: 'label.addStepByClicking' })}
          </Typography>
        )}

      <Loading isLoading={isLoading || isSubmitting} />
    </MrlLayout>
  );
};

export default MrlGuide;
