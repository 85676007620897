
const MuiTab = {
  styleOverrides: {
    root: ({ theme }) => ({
      minHeight: '22px',
      margin: theme.spacing(0, 6, 0, 0),

      '& .MuiTypography-root': {
        color: theme.palette.text.secondary,
      },

      '& svg > path:not(#mandatory-color), & svg > g > path, & svg > rect': {
        fill: theme.palette.text.secondary,
      },

      '&.Mui-selected': {
        '& .MuiTypography-root': {
          color: theme.palette.primary.main,
          fontWeight: 700,
        },

        '& svg > path:not(#mandatory-color), & svg > g > path, & svg > rect': {
          fill: theme.palette.primary.main,
        },
      },
    }),
  },
};

export default MuiTab;
