import { Box, Divider } from '@mui/material';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import HtmlEditor from '../../../common/components/HtmlEditor';
import PageTitle from '../../../common/components/PageTitle';
import { Loading } from '../../../common/styled';
import SectionTitle from '../../components/SectionTitle';
import { StageTitle } from '../../styled/MrlStyled';

import { createMrlTask, editTask, getTaskDetails } from '../../../modules/mrl/services/mrl';

import { handleApiError } from '../../../utils/errorUtils';
import { getMrlTaskSchema } from '../../../utils/validation/mrlTaskSchema';
import { getInitialValues } from '../../utils/taskUtils';

import { Root } from './index.styled';

const EditStepTask = () => {
  const intl = useIntl();
  const history = useHistory();
  const { stepId, taskId } = useParams();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [task, setTask] = useState({});
  const userLanguage = useSelector(state => state?.userProfile?.userProfile?.language);

  useEffect(() => {
    if (stepId && taskId) {
      fetchTask();
    }
  }, [stepId, taskId]);

  const handleRedirect = () => history.push('/admin/mrl?tab=mrlSteps');

  const handleCreate = async (values) => {
    setIsSubmitting(true);

    try {
      await createMrlTask(stepId, values);
      handleRedirect();
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEdit = async (values) => {
    setIsSubmitting(true);

    try {
      await editTask(stepId, taskId, values);
      handleRedirect();
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const fetchTask = async () => {
    setIsLoading(true);

    try {
      const response = await getTaskDetails(stepId, taskId);
      setTask(response?.data);
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = ({ target: { value, name }}, onSetFieldValue) => {
    onSetFieldValue(name, value);
  };

  return (
    <Root className="scrollbar">
      <PageTitle
        title={intl.formatMessage({ id: 'label.backToMRL' })}
        onBack={history.goBack}
      />
      <Divider sx={{ borderColor: 'general.borderAccent' }} />
      <Box display="flex" justifyContent="center" mt={20} width="100%">
        <Formik
          initialValues={getInitialValues(task, userLanguage)}
          validationSchema={getMrlTaskSchema(userLanguage)}
          onSubmit={taskId ? handleEdit : handleCreate}
          enableReinitialize
        >
          {({ handleSubmit, values, setFieldValue }) => (
            <form onSubmit={handleSubmit} noValidate>
              <SectionTitle
                title={intl.formatMessage({ id: 'label.task' })}
                isDisabled={!values.name?.[userLanguage] || isSubmitting}
                sx={{ mb: 10 }}
              />
              <StageTitle
                placeholder={intl.formatMessage({ id: 'label.taskName' })}
                name={`name.${userLanguage}`}
                value={values?.name?.[userLanguage]}
                onChange={(e) => handleChange(e, setFieldValue)}
              />
              <StageTitle
                placeholder={intl.formatMessage({ id: 'label.linkButton' })}
                name="link"
                value={values?.link}
                onChange={(e) => handleChange(e, setFieldValue)}
              />
              <StageTitle
                placeholder={intl.formatMessage({ id: 'label.buttonName' })}
                name={`buttonName.${userLanguage}`}
                value={values?.buttonName?.[userLanguage]}
                onChange={(e) => handleChange(e, setFieldValue)}
              />
              <HtmlEditor
                content={values?.description?.[userLanguage]}
                onChange={(value) => setFieldValue(`description.${userLanguage}`, value)}
                topOffset="200px"
              />
            </form>
          )}
        </Formik>
      </Box>

      <Loading isLoading={isLoading || isSubmitting} />
    </Root>
  );
};

export default EditStepTask;
