import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';

import {
  TrashIconOutlined,
  EditIcon,
  EyeIconCrossed,
} from '../../assets/icons';

const RoundElement = ({ number, borderColor, numberColor }) => (
  <Box
    sx={{
      width: 22,
      height: 22,
      borderRadius: '50%',
      border: `1px solid ${borderColor}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: `${numberColor}`,
    }}
  >
    {number}
  </Box>
);
const Article = ({
  title = '',
  categoryName = '',
  isVisible = false,
  handleEdit,
  handleDelete,
  order,
}) => {
  const articleColor = isVisible ? '#74A3FE' : '#DEE7FC';
  return (
    <Box
      onClick={handleEdit}
      sx={{
        border: `1px solid ${articleColor}`,
        width: '100%',
        height: '64px',
        borderRadius: '6px',
        display: 'grid',
        gridTemplateColumns: '2fr 1fr 1fr',
        alignContent: 'center',
        padding: '20px',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#DEE7FC',
        },
        '& > :last-child': {
          justifySelf: 'end',
        },
        '& > p': {
          display: 'flex',
          alignItems: 'center',
        },
      }}
    >
      <Box sx={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
        <RoundElement
          number={order}
          borderColor="#74A3FE"
          numberColor="#74A3FE"
        />

        <Typography className={!isVisible ? 'faded' : ''}>{title}</Typography>
      </Box>
      <Typography className={!isVisible ? 'faded' : ''}>{categoryName}</Typography>
      <Box sx={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
        {!isVisible && (
          <Box sx={{ cursor: 'default' }}>
            <EyeIconCrossed fill={articleColor} />
          </Box>
        )}
        <IconButton onClick={(e) => handleEdit(e)}>
          <EditIcon width="16" height="18" />
        </IconButton>
        <IconButton color="error" onClick={(e) => handleDelete(e)}>
          <TrashIconOutlined width="16" height="18" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Article;
