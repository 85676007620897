import styled from 'styled-components';

export const StageTitle = styled.input`
  display: flex;
  align-items: center;
  background: #ffffff;
  border: ${(props) => `1px solid ${props.theme.blueAccent}`};
  border-radius: 5px;
  width: 100%;
  height: 5vh;
  margin-left: ${({ marginLeft }) => marginLeft && marginLeft};
  padding: 10px;
  margin-bottom: 10px;
`;
