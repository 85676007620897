import Cookie from 'js-cookie';
import { find, get, map, orderBy, size } from 'lodash-es';

export const getFormattedTeams = (userId, currentTeams) => {
  let userTeams;

  const selectedTeam = get(
    Cookie.getJSON(`selectedTeam-${userId}`),
    'teamId'
  );

  if (!selectedTeam) {
    if (size(currentTeams)) {
      Cookie.set(`selectedTeam-${userId}`, { teamId: currentTeams[0]._id });
      userTeams = map(currentTeams, (team, index) =>
        index === 0 ? { ...team, selected: true } : team
      );
    }

    return userTeams;
  }

  if (!find(currentTeams, { _id: selectedTeam })) {
    Cookie.remove(`selectedTeam-${userId}`);
    return size(currentTeams) > 0
      ? map(currentTeams, (team, index) => index === 0 ? { ...team, selected: true } : team)
      : undefined;
  }

  userTeams = map(currentTeams, (team) =>
    team._id === selectedTeam ? { ...team, selected: true } : team
  );

  return orderBy(userTeams, ['createdAt'], ['desc']);
};

export const setCurrentUserTeam = (userId, teamId) => {
  Cookie.set(`selectedTeam-${userId}`, { teamId });
};
