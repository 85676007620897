import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { NAVBAR_HEIGHT } from '../../../constants/general';

export const Root = styled(Box)(({ theme }) =>  `
   background-color: ${theme.palette.common.white};
   position: sticky;
   top: 0;
   z-index: 1;
   height: ${NAVBAR_HEIGHT}px;
   display: flex;
   align-items: center;
   gap: ${theme.spacing(16)};
`);
