import { Box, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import Article from './Article';
import ArticlePageHeaderSection from './ArticlePageHeaderSection';

const ArticlesList = ({
  toggleFiltersModal,
  createNewArticle,
  articles = [],
  toggleValue,
  handleToggleValue,
  handleEditArticle,
  filters,
  handleDeleteArticle,
}) => {
  const filtersNumber = filters.categories.length + filters.visibility.filter((item) => item !== 'all').length;
  const userLanguage = useSelector(state => state?.userProfile?.userProfile?.language);

  return (
    <>
      <ArticlePageHeaderSection
        handleFiltersModalStatus={toggleFiltersModal}
        hasFilters
        filtersNumber={filtersNumber}
        toggleValue={toggleValue}
        handleToggleValue={handleToggleValue}
        onAddClick={createNewArticle}
        headerTitle="Articole"
      />
      {!articles.length ? (
        <Typography className="faded" mt={10}>
          Adauga un articol facand click pe butonul din dreapta sau verifica
          filtrele...
        </Typography>
      ) : (
        <Box
          className="scrollbar"
          sx={{
            marginTop: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '10px',
            overflowY: 'auto',
            paddingBottom: '20px',
            width: '100%',
          }}
        >
          {articles.map(({ _id: id, categoryId, title, isVisible }, index) => (
            <Article
              key={index}
              order={index + 1}
              categoryName={categoryId ? categoryId.name : ''}
              isVisible={isVisible}
              title={title?.[userLanguage]}
              handleEdit={(e) => handleEditArticle(id, e)}
              handleDelete={(e) => handleDeleteArticle(id, e)}
            />
          ))}
        </Box>
      )}
    </>
  );
};

export default ArticlesList;
