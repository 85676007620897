const getReorderedItems = (items) => items?.map(({ _id }, index) => ({
  id: _id,
  order: index,
}));

export const getReorderedSteps = (result, steps) => {
  const { source, destination } = result;

  if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
    return [];
  }

  const copiedItems = [...steps];
  const [removed] = copiedItems.splice(source.index, 1);
  copiedItems.splice(destination.index, 0, removed);

  return getReorderedItems(copiedItems);
};

export const getReorderedTasks = (result, steps) => {
  const { source, destination } = result;

  if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
    return [];
  }

  // This checks if the task was moved to a different MRL Step
  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = steps?.find(({ _id }) => _id === source.droppableId);
    const destColumn = steps?.find(({ _id }) => _id === destination.droppableId);
    const sourceItems = [...sourceColumn.tasks];
    const destItems = [...destColumn.tasks];
    const [removed] = sourceItems.splice(source.index, 1);

    removed.columnBMC = result.destination.droppableId;
    destItems.splice(destination.index, 0, removed);

    return {
      sourceItems: getReorderedItems(sourceItems),
      destItems: getReorderedItems(destItems),
    };
  } else {
    const column = steps?.find(({ _id }) => _id === source.droppableId);
    const copiedItems = [...column.tasks];
    const [removed] = copiedItems.splice(source.index, 1);

    removed.order = result.destination.index;
    copiedItems.splice(destination.index, 0, removed);

    return {
      sourceItems: getReorderedItems(copiedItems),
      destItems: null,
    };
  }
};
