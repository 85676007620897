import { Box, IconButton, useTheme } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { ClampedRichText } from '../../../common/components/ClampedRichText';
import DialogAlert from '../../../common/components/DialogAlert';
import GenericEmptyTable from '../../../common/components/GenericEmptyTable';
import GenericTableComponent from '../../../common/components/GenericTableComponent';
import AddActivitiesModal from '../../../common/modals/AddActivityModal';

import { currentTeamIdSelector } from '../../../common/store';
import { useApi } from '../../../hooks/useApi';
import {
  deleteActivity,
  getAllActivitiesByTeam,
  updateActivity,
} from '../../../requests/activities';
import { getTeamMembers } from '../../../requests/teams';
import { getTeam } from '../../../store/idea/teamSlice';

import { TASK_STATUS_OPTIONS } from '../../../constants/options';
import { formatDate } from '../../../utils/dateHelpers';
import { handleApiError } from '../../../utils/errorUtils';
import { formatMembersToOptions } from '../../../utils/formatters';

import { EditIcon, TrashIconOutlined } from '../../../assets/icons';

const ActivitiesComponent = () => {
  const intl = useIntl();
  const theme = useTheme();
  const dispatch = useDispatch();
  const teamId = useSelector((state) => currentTeamIdSelector(state.common.userTeams));
  const [{ result: activities }, fetchAllActivitiesByTeam] = useApi(getAllActivitiesByTeam, []);
  const [teamMembers, setTeamMembers] = useState([]);
  const [, removeActivity] = useApi(deleteActivity);
  const [, doUpdateActivity] = useApi(updateActivity);

  const [selectedActivityId, setSelectedActivityId] = useState(null);
  const [editFormValues, setEditFormValues] = useState(null);
  const [isAddActivitiesModalOpen, setIsAddActivitiesModalOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState('');

  const teamMembersOptions = useMemo(() => (
    formatMembersToOptions(teamMembers)
  ), [teamMembers]);

  const fetchTeamMembers = async () => {
    try {
      const response = await getTeamMembers(teamId);
      setTeamMembers(response?.data);
    } catch (e) {
      handleApiError(e);
    }
  };

  const handleToggleAddActivitiesModal = () => {
    setIsAddActivitiesModalOpen(!isAddActivitiesModalOpen);
  };

  const handleCloseModal = () => {
    handleToggleAddActivitiesModal();
    setSelectedActivityId(null);
    setEditFormValues(null);
  };

  const handleUpdateActivity = async (formValues) => {
    const payload = {
      params: { activityId: selectedActivityId },
      activityData: {
        ...formValues,
        status: formValues.state,
      },
    };
    await doUpdateActivity(payload);
    await handleGetActivities();
    handleCloseModal();
  };

  const handleEditActivity = (id) => {
    const foundActivity = activities.find((activity) => activity._id === id);

    setSelectedActivityId(id);
    setEditFormValues({
      state: foundActivity?.status,
      userResponsibleId: foundActivity?.userResponsibleId?._id,
      successCriteria: foundActivity?.successCriteria,
      title: foundActivity?.title,
    });
    handleToggleAddActivitiesModal();
  };

  const handleDeleteActivity = async () => {
    const payload = {
      params: { activityId: idToDelete },
    };
    await removeActivity(payload);
    await handleGetActivities();
    setIdToDelete('');
  };

  const createTableHeaderCells = () => [
    { type: 'string', content: intl.formatMessage({ id: 'label.number' }), key: 'number' },
    { type: 'string', content: intl.formatMessage({ id: 'label.title' }), key: 'title' },
    { type: 'string', content: intl.formatMessage({ id: 'label.study' }), key: 'status' },
    { type: 'string', content: intl.formatMessage({ id: 'label.completionDate' }), key: 'createdAt' },
    ...(activities.length ? [{ type: 'string', content: '', hideSortIcon: true }] : []),
  ];

  const createRows = () => activities.map(({ _id, title, status, createdAt }, index) => ({
      rowId: _id,
      order: index + 1,
      title: <ClampedRichText variant="body2" color="primary" text={title} lineClamp={1} />,
      status: TASK_STATUS_OPTIONS.find((option) => option.value === status).label,
      createdAt: formatDate(createdAt),
      action: (
        <Box sx={{ display: 'flex', gap: '18px' }}>
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              setIdToDelete(_id);
            }}
            color="error"
          >
            <TrashIconOutlined />
          </IconButton>
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              handleEditActivity(_id);
            }}
          >
            <EditIcon size="18px" fill={theme.palette.general.accentLight} />
          </IconButton>
        </Box>
      ),
    })
  );

  const handleGetActivities = async () => {
    const payload = {
      params: { teamId },
      queryParams: { filters: {} },
    };

    await fetchAllActivitiesByTeam(payload);
  };

  const handleGetTeam = async () => {
    await dispatch(getTeam(teamId));
  };

  useEffect(() => {
    if (teamId) {
      handleGetActivities();
      handleGetTeam();
      fetchTeamMembers();
    }
  }, []);

  const handleSelectedActivity = () => {};
  return (
    <>
      {activities && !!activities.length ? (
        <GenericTableComponent
          rows={createRows()}
          headerCells={createTableHeaderCells()}
          handleSelectedRow={handleSelectedActivity}
        />
      ) : (
        <GenericEmptyTable
          headers={createTableHeaderCells()}
          detailMessage={`${intl.formatMessage({ id: 'label.noActivityWasAdded' })}...`}
        />
      )}

      {isAddActivitiesModalOpen && (
        <AddActivitiesModal
          teamId={teamId}
          isModalOpen={isAddActivitiesModalOpen}
          onClose={handleCloseModal}
          onSubmit={handleUpdateActivity}
          activity={editFormValues}
          teamMembers={teamMembersOptions}
          isEditMode
        />
      )}

      {idToDelete && (
        <DialogAlert
          isModalOpen={idToDelete}
          onClose={() => setIdToDelete('')}
          title={intl.formatMessage({ id: 'modal.title.confirmDeleteOp' })}
          content={intl.formatMessage({ id: 'modal.content.deleteActivity' })}
          hasActions
          onSubmit={handleDeleteActivity}
        />
      )}
    </>
  );
};

export default ActivitiesComponent;
