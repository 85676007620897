import { Formik } from 'formik';
import { useIntl } from 'react-intl';

import ButtonComponent from '../../../../common/components/ButtonComponent';
import CustomerSegmentChip from '../../../../common/components/CustomerSegmentChip';
import CustomerSegmentOption from '../../../../common/components/CustomerSegmentOption';
import { DialogActionWrapper } from '../../../../common/components/DialogComponent/index.styled';
import CustomMultipleSelectField from '../../../../common/forms/formComponents/CustomMultipleSelectField';

import { BUSINESS_OBJECTIVES_OPTIONS } from '../../../../constants/options';
import { getFormikErrors } from '../../../../utils/errorUtils';
import { viabilityModelSchema } from '../../../../utils/validation/viabilityModelSchema';
import { getInitialValues } from '../../utils';

import { SaveIconOutlined } from '../../../../assets/icons';

import AutocompleteField from '../../../../fields/AutocompleteField/index.formik';
import CounterField from '../../../../fields/CounterField';

const ViabilityModelForm = ({
  formValues,
  onSubmit,
  options,
}) => {
  const intl = useIntl();

  // eslint-disable-next-line no-unused-vars
  const CustomOptionComponent = ({ option, selected, ...otherProps }) => (
    <CustomerSegmentOption {...otherProps} color={option.color} label={option.label} />
  );

  const handleValidation = (values) => {
    try {
      viabilityModelSchema.validateSync(values, { abortEarly: false });
    } catch (e) {
      return getFormikErrors(e);
    }
  };

  return (
    <Formik
      initialValues={getInitialValues(formValues)}
      onSubmit={onSubmit}
      validate={handleValidation}
    >
      {({ handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit} noValidate>
          <AutocompleteField
            id="businessObjective"
            placeholder={intl.formatMessage({ id: 'placeholder.pickAnObjective' })}
            label={`${intl.formatMessage({ id: 'label.businessObjective' })} *`}
            options={BUSINESS_OBJECTIVES_OPTIONS}
            getOptionLabel={(option) => intl.formatMessage({ id: option.label })}
          />

          <CounterField
            id="objectiveCompletionTime"
            label={intl.formatMessage({ id: 'label.objectiveDeadline' })}
            minValue={1}
            maxValue={10}
            required
            isEditable
            onChangeValue={(oldValue, newValue) => setFieldValue('objectiveCompletionTime', newValue)}
          />

          <CounterField
            id="customerConversionRate"
            label={intl.formatMessage({ id: 'label.customerConversionRate' })}
            minValue={1}
            maxValue={100}
            required
            isEditable
            onChangeValue={(oldValue, newValue) => setFieldValue('customerConversionRate', newValue)}
          />

          <CounterField
            id="qualifiedCustomerConversionRate"
            label={intl.formatMessage({ id: 'label.qualifiedCustomerConversionRate' })}
            minValue={1}
            maxValue={100}
            required
            isEditable
            onChangeValue={(oldValue, newValue) => setFieldValue('qualifiedCustomerConversionRate', newValue)}
          />

          <CounterField
            id="customerDropRate"
            label={intl.formatMessage({ id: 'label.customerChurnRate' })}
            minValue={1}
            maxValue={100}
            required
            isEditable
            onChangeValue={(oldValue, newValue) => setFieldValue('customerDropRate', newValue)}
          />

          <CounterField
            id="mvpDevelopmentDuration"
            label={intl.formatMessage({ id: 'label.mvpDevelopmentTerm' })}
            minValue={1}
            required
            maxValue={36}
            isEditable
            onChangeValue={(oldValue, newValue) => setFieldValue('mvpDevelopmentDuration', newValue)}
          />

          <CustomMultipleSelectField
            id="customerSegments"
            label={`${intl.formatMessage({ id: 'label.clientsSegments' })} *`}
            options={options?.customerSegments}
            customSelectedOptionComponent={CustomerSegmentChip}
            customOptionComponent={CustomOptionComponent}
            placeholder={intl.formatMessage({ id: 'placeholder.pickSegments' })}
          />

          <AutocompleteField
            id="revenueStreamsHypothesisId"
            placeholder={intl.formatMessage({ id: 'placeholder.pickRevenueStream' })}
            label={`${intl.formatMessage({ id: 'label.revenueStreams' })} *`}
            options={options?.revenueStreams}
            disabled={!options?.revenueStreams?.length}
          />

          <DialogActionWrapper>
            <ButtonComponent
              type="submit"
              icon={<SaveIconOutlined size="16px" />}
              color="secondary"
              label={intl.formatMessage({ id: 'button.save' })}
            />
          </DialogActionWrapper>
        </form>
      )}
    </Formik>
  );
};

export default ViabilityModelForm;
