import { LinearProgress, Typography, Box } from '@mui/material';

const LinearProgressWithLabel = ({
  value,
  sx,
  ...remainingProps
}) => (
  <Box display="flex" alignItems="center" justifyContent="space-between" gap={8} sx={sx}>
    <Box width="100%">
      <LinearProgress
        variant="determinate"
        value={value}
        sx={{ height: '12px', borderRadius: '6px' }}
        {...remainingProps}
      />
    </Box>
    <Typography variant="body1" color="primary" fontWeight={700}>
      {`${Math.round(value)}%`}
    </Typography>
  </Box>
);

export default LinearProgressWithLabel;
