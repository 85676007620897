import React from 'react';
import { Redirect, Switch, withRouter } from 'react-router';

import {
  ContactForm,
  EditArticle,
  MrlPage,
  ResourcesPageResolver,
  UsersPageResolver,
} from '../admin/pages';
import EditChapter from '../admin/pages/EditChapter';
import EditMrlStep from '../admin/pages/EditMrlStep';
import EditStepTask from '../admin/pages/EditStepTask';
import Page from '../layouts/Page';
import AdminRoute from '../routes/AdminRoute';

const AdminRouter = () => (
  <Page>
    <Switch>
      <AdminRoute exact path="/admin/users" component={UsersPageResolver} />
      <AdminRoute exact path="/admin/mrl" component={MrlPage} />
      <AdminRoute
        exact
        path="/admin/mrl/article/form"
        component={EditArticle}
      />
      <AdminRoute exact path="/admin/mrl/course/form" component={EditChapter} />
      <AdminRoute exact path="/admin/mrl/steps/:stepId" component={EditMrlStep} />
      <AdminRoute exact path="/admin/mrl/step/create" component={EditMrlStep} />
      <AdminRoute exact path="/admin/mrl/steps/:stepId/tasks/:taskId" component={EditStepTask} />
      <AdminRoute exact path="/admin/mrl/steps/:stepId/task/create" component={EditStepTask} />
      <AdminRoute
        exact
        path="/admin/resources"
        component={ResourcesPageResolver}
      />
      <AdminRoute exact path="/admin/contact-form" component={ContactForm} />
      <Redirect path="/admin" to={{ pathname: '/admin/users' }} />
    </Switch>
  </Page>
);
export default withRouter(AdminRouter);
