import { Grid, IconButton } from '@mui/material';
import { get } from 'lodash-es';
import { useIntl } from 'react-intl';

import { TrashIconOutlined } from '../../../../assets/icons';

import AutocompleteField from '../../../../fields/AutocompleteField/index.formik';
import InputField from '../../../../fields/InputField';

const CharacteristicRow = ({
  parentName,
  position,
  values,
  setValues,
  options,
  label,
  sx = {},
  hasIntensity = false,
  minSize = 0,
}) => {
  const intl = useIntl();
  const hasDeleteOption = values?.[parentName]?.length > minSize;

  const handleDelete = () => {
    const array = get(values, parentName);
    array.splice(position, 1);
    setValues({ ...values, [`${parentName}Number`]: values?.[`${parentName}Number`] - 1, [parentName]: array });
  };

  return (
    <Grid container spacing={8} sx={sx} alignItems="flex-start">
      <Grid item xs={12} md={hasIntensity ? 7 : 10.5}>
        <AutocompleteField
          id={`${parentName}[${position}].id`}
          label={intl.formatMessage({ id: label })}
          options={options}
          disabled={!options?.length}
        />
      </Grid>
      {hasIntensity && (
        <Grid item xs={5.5} md={3.5}>
          <InputField
            id={`${parentName}[${position}].intensity`}
            label={intl.formatMessage({ id: 'label.intensity' })}
            type="number"
            placeholder="1-5"
          />
        </Grid>
      )}
      <Grid item xs={1.5} mt={8}>
        <IconButton
          sx={{ mb: 15 }}
          onClick={handleDelete}
          disabled={!hasDeleteOption}
          color="error"
        >
          <TrashIconOutlined />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default CharacteristicRow;
