import {
  Divider,
  Typography,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import ButtonComponent from '../../../common/components/ButtonComponent';
import DialogAlert from '../../../common/components/DialogAlert';

import { deleteMrlStep, deleteStepTask } from '../../../modules/mrl/services/mrl';

import { handleApiError } from '../../../utils/errorUtils';

import { AddIcon, ArrowDown } from '../../../assets/icons';

import DetailsActionRow from '../DetailsActionRow';
import DraggableWrapper from '../DraggableWrapper';
import { AccordionDetails, AccordionSummary, Accordion } from './index.styled';

const StepAccordion = ({
  step,
  onFetchSteps,
}) => {
  const history = useHistory();
  const { isVisible, tasks = [], _id: stepId, name: stepTitle } = step;
  const [isExpanded, setExpanded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [entityIdToDelete, setEntityIdToDelete] = useState(null);
  const userLanguage = useSelector(state => state?.userProfile?.userProfile?.language);

  const sortedTasks = useMemo(() => (
    tasks.sort((a, b) => a.order - b.order)
  ), [step]);

  const handleAccordionChange = (event, newExpanded) => {
    setExpanded(newExpanded);
  };

  const handleEditStep = () => history.push(`/admin/mrl/steps/${stepId}`);

  const handleEditTask = (taskId) => history.push(`/admin/mrl/steps/${stepId}/tasks/${taskId}`);

  const handleCreateTask = () => history.push(`/admin/mrl/steps/${stepId}/task/create`);

  const handleOpen = (event, id) => {
    event.stopPropagation();
    setIsOpen(true);
    setEntityIdToDelete(id);
  };

  const handleDelete = async () => {
    try {

      if (entityIdToDelete === stepId) {
        await deleteMrlStep(stepId);
      } else {
        await deleteStepTask(stepId, entityIdToDelete);
      }

      await onFetchSteps();
      handleCloseDialog();
    } catch (e) {
      handleApiError(e);
    }
  };

  const handleCloseDialog = () => setIsOpen(false);

  return (
    <Accordion expanded={isExpanded} onChange={handleAccordionChange} isVisible={isVisible}>
      <AccordionSummary expandIcon={<ArrowDown size="20px" />}>
        <DetailsActionRow
          title={stepTitle?.[userLanguage]}
          onEdit={() => handleEditStep(stepId)}
          onDelete={(e) => handleOpen(e, stepId)}
          isVisible={isVisible}
          sx={{ mr: 8 }}
        />
      </AccordionSummary>

      <Divider />

      <AccordionDetails>
        <Typography variant="h3" color="primary">
          Task-uri
        </Typography>
        <Droppable droppableId={stepId} type="TASKS">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {sortedTasks.length > 0
                ? sortedTasks.map((task, index) => (
                  <DraggableWrapper index={index} entityId={task._id} key={task._id}>
                    <DetailsActionRow
                      title={task?.name?.[userLanguage]}
                      isVisible
                      onEdit={() => handleEditTask(task._id)}
                      onDelete={(e) => handleOpen(e, task._id)}
                    />
                    <Divider />
                  </DraggableWrapper>
                )) : (
                  <Typography className="faded" mt={20}>
                    Adauga un task facand click pe butonul de mai jos...
                  </Typography>
                )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <ButtonComponent
          color="secondary"
          onClick={handleCreateTask}
          icon={<AddIcon fill='currentColor' width='22px' height='22px' />}
          label="Adauga un task"
          sx={{ mt: 20 }}
        />
      </AccordionDetails>

      <DialogAlert
        isModalOpen={isOpen}
        onClose={handleCloseDialog}
        title="Confirmare stergere"
        content={entityIdToDelete === stepId
          ? 'Sunteti sigur ca doriti stergerea pasului?'
          : 'Sunteti sigur ca doriti stergerea task-ului?'}
        hasActions
        onSubmit={handleDelete}
      />
    </Accordion>
  );
};

export default StepAccordion;
