export const getMrlProgress = (mrls, teamTasks) => {
  let totalTasks = 0;
  let completedTasks = 0;

  if (!mrls?.length || !teamTasks?.length) {
    return 0;
  }

  mrls?.forEach(({ tasks }) => totalTasks += tasks?.length);

  teamTasks?.forEach(({ isDone }) => completedTasks += isDone ? 1 : 0);

  return completedTasks * 100 / totalTasks;
};

export const calculateCompletionStatus = (currentTeam, currentStepId, totalTasks) => {
  let completedTasks = 0;

  currentTeam?.mrl?.forEach(({ stepId, isDone }) => {
    if (currentStepId === stepId && isDone) {
      completedTasks += 1;
    }
  });

  return `${completedTasks}/${totalTasks}`;
};
