import { Box, Typography } from '@mui/material';

const StatsSection = ({
  label,
  value,
}) => (
  <Box display="flex" flexDirection="column" alignItems="center">
   <Typography variant="body2" color="textSecondary">
     {label}
   </Typography>
    <Typography variant="body1" color="primary" fontWeight={700}>
      {value}
    </Typography>
  </Box>
);

export default StatsSection;
