import { Box, Divider, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

import ButtonComponent from '../../../common/components/ButtonComponent';

import { SaveIconOutlined } from '../../../assets/icons';

import SwitchField from '../../../fields/SwitchField';

const SectionTitle = ({
  title,
  isDisabled,
  sx = {},
  hasToggle,
}) => {
  const intl = useIntl();

  return (
    <Box display="flex" width="100%" sx={sx} justifyContent="space-between">
      <Typography variant="h2" color="primary">
        {title}
      </Typography>
      <Box display="flex" alignItems="center" gap={10}>
        {hasToggle && (
          <>
            <SwitchField
              id="isVisible"
              labelLeft={intl.formatMessage({ id: 'label.hidden' })}
              labelRight={intl.formatMessage({ id: 'label.visible' })}
            />
            <Divider orientation="vertical" sx={{ borderColor: 'general.borderAccent' }} />
          </>
        )}

        <ButtonComponent
          variant="contained"
          color="secondary"
          type="submit"
          disabled={isDisabled}
          icon={<SaveIconOutlined fill="currentColor" size="18px" />}
          label={intl.formatMessage({ id: 'button.save' })}
        />
      </Box>
    </Box>
  );
};

export default SectionTitle;
