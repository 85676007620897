import { EXISTING_FLAGS, isFeatureDisabled } from '../../utils/featureFlag';

import {
  EarthIcon,
  GroupOfPeopleIcon,
  Paperclip,
  ThermostatIcon,
} from '../../assets/icons';

export const MY_IDEA_TABS = [
  {
    key: 'description',
    label: 'label.descriptionAndTeam',
    icon: <GroupOfPeopleIcon />,
  },
  {
    key: 'profile',
    label: 'label.publicProfile',
    icon: <EarthIcon />,
    isHidden: isFeatureDisabled(EXISTING_FLAGS.myIdea.publicProfile),
  },
  {
    key: 'details',
    label: 'label.details',
    icon: <Paperclip />,
  },
  {
    key: 'mrl',
    label: 'label.mrlGuide',
    icon: <ThermostatIcon />,
    isHidden: isFeatureDisabled(EXISTING_FLAGS.myIdea.mrlGuide),
  },
];
