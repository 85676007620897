import { Container } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import PlanOptions from '../../components/PlanOptions';
import PlansDescription from '../../components/PlansDescription';
import SubscriptionType from '../../components/SubscriptionType';

const Subscriptions = () => {
  const [isYearlyActive, setIsYearlyActive] = useState(true);
  const currentSubscription = useSelector((state) => state?.userProfile?.userProfile?.userId?.subscription) || {};

  useEffect(() => {
    if (currentSubscription?.planRecurringPeriod) {
      setIsYearlyActive(currentSubscription.planRecurringPeriod === 'yearly');
    }
  }, [currentSubscription?.planRecurringPeriod]);

  const handleToggle = () => {
    setIsYearlyActive(!isYearlyActive);
  };

  return (
    <Container maxWidth="md" sx={{ py: 40 }}>
      <SubscriptionType isYearlyActive={isYearlyActive} onToggle={handleToggle} />
      <PlanOptions isYearlyActive={isYearlyActive} />
      <PlansDescription />
    </Container>
  );
};

export default Subscriptions;
