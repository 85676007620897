// TRENDS
export const GET_ALL_TRENDS = 'trends/get-all/teams/:teamId';
export const DELETE_TREND_BY_ID = 'trends/delete/:trendId';

// CHAT GPT
export const POST_CHAT_GPT_CONVERSATION = 'openai/chat';

// COMMENTS AND NOTIFICATIONS
export const GET_COMMENTS_CSV = 'comments/export-csv/teams/:teamId';
export const PUT_MARK_COMMENT_AS_READ = 'comments/mark-read/';

// PAYMENTS
export const START_CHECKOUT_SESSION = 'create-checkout-session';
export const UPDATE_USER_SUBSCRIPTION = 'updateSubscription';
export const PREVIEW_UPDATE_USER_SUBSCRIPTION = 'previewUpdateSubscription';
export const CANCEL_SUBSCRIPTION = 'cancelSubscription';
export const USER_NEXT_PAYMENT = 'payments-next-one';

// CHAPTERS
export const GET_ALL_CHAPTERS = 'chapters/get-all';
export const GET_CHAPTER_BY_ID = 'chapters/get-by-id/:chapterId';
export const POST_ADD_CHAPTER = 'chapters/add';
export const PUT_EDIT_CHAPTER = 'chapters/edit/:chapterId';
export const DELETE_CHAPTER_BY_ID = 'chapters/delete/:chapterId';
export const PUT_ORDER_CHAPTERS = 'chapters/order';
export const MARK_CHAPTER_ROUTE = 'chapters/mark-read/:chapterId';

// ARTICLES
export const GET_ALL_ARTICLES = 'articles/get-all';
export const GET_ARTICLE_BY_ID = 'articles/get-by-id/:articleId';

// DETAILS
export const CREATE_DETAIL = 'details/add/teams/:teamId';
export const EDIT_DETAIL = 'details/edit/:detailId';
export const GET_ALL_DETAILS = 'details/get-all/teams/:teamId';
export const GET_DETAIL_BY_ID = 'details/get-by-id/:detailId';
export const DELETE_DETAIL_BY_ID = 'details/delete/:detailId';
export const GET_PARENT_DETAILS = 'details/get-all-by-parent/:parentDetailId';

// TEAMS
export const GET_TEAM_MEMBERS = 'team/:teamId/members';
export const GET_TEAM_MENTORS = 'team/:teamId/mentors';
export const ADD_TEAM_MEMBER = 'team/:teamId/members/add';
export const ADD_TEAM_MENTOR = 'team/:teamId/mentors/add';
export const GET_TEAM_COHORTS = 'team/get-all/:cohortId';
export const JOIN_TEAM_COHORT = 'team/join-cohort/:teamId';
export const REMOVE_TEAM_MEMBER = 'team/remove-member/:email/:teamId';
export const RESEND_TEAM_MEMBER_INVITATION = 'team/:teamId/new-member-invitation/resend-email/:email';
export const GET_TEAM_BY_ID = 'team/get/:teamId';
export const POST_NEW_TEAM = 'team/add';
export const PUT_ACCEPT_TEAM_INVITE = 'team/accept-invite';
export const PUT_REJECT_TEAM_INVITE = 'team/reject-invite';
export const DELETE_TEAM_BY_ID = 'team/:teamId/leave';
export const SAVE_TEAM_INVITATION = 'team/edit-invitations/:teamId';
export const EDIT_TEAM_EQUITY = 'team/:teamId/edit-equity';
export const TRANSFER_OWNERSHIP = 'team/member-to-owner/:teamId';
export const COMMUNITY_ROUTE = 'team/get-all-community';
export const JOIN_TEAM_ROUTE = 'team/join';
export const EDIT_TEAM_LOGO_ROUTE = 'team/edit-team-logo/:teamId';
export const EDIT_TEAM_ROUTE = 'team/edit/:teamId';
export const UPDATE_TEAM_MRL_TASK = 'team/edit-mrl-task/:teamId';

// Activities
export const GET_ALL_ACTIVITIES_BY_TEAM = 'activities/get-all/teams/:teamId';
export const CREATE_ACTIVITY = 'activities/add/teams/:teamId';
export const UPDATE_ACTIVITY = 'activities/edit/:activityId';
export const DELETE_ACTIVITY = 'activities/delete/:activityId';
export const GET_ACTIVITY_BY_ID = 'activities/get-by-id/:activityId';

// Conclusions
export const CREATE_CONCLUSION = 'conclusions/add/activities/:activityId';
export const UPDATE_CONCLUSION_BY_ID = 'conclusions/edit/:conclusionId';
export const DELETE_CONCLUSION_BY_ID = 'conclusions/delete/:conclusionId';
export const GET_ALL_CONCLUSIONS_BY_TEAM = 'conclusions/get-all/teams/:teamId';
export const GET_CONCLUSIONS_DETAILS_BY_ID = 'conclusions/get-by-id/:conclusionId';

// Task
export const CREATE_TASK = 'tasks/add/activities/:activityId';
export const UPDATE_TASK_BY_ID = 'tasks/edit/:taskId';
export const DELETE_TASK_BY_ID = 'tasks/delete/:taskId';
export const GET_ALL_TASKS_BY_TEAM = 'tasks/get-all/teams/:teamId';
export const GET_ALL_TASKS_BY_ACTIVITY = 'tasks/get-all/activities/:activityId';
export const GET_TASK_DETAILS_BY_ID = 'tasks/get-by-id/:taskId';

// QUESTIONNAIRE
export const GET_QUESTIONNAIRE_PROGRESS = 'questions/get-all/:userId';
export const POST_QUESTIONNAIRE_ANSWER = 'questions/add';
export const UPDATE_QUESTIONNAIRE_ANSWER = 'questions/update/:questionId';

export const PUT_FINISH_QUESTIONAIRE = 'account/complete-questionnaire';

// PROFILE
export const GET_USER_PROFILE = 'profile/get';
export const GET_ALL_TEAMS = 'user/:userId/get-all-teams';

// PAYMENTS
export const GET_ALL_SUBSCRIPTIONS = 'products-get-all';

// REGISTRATION
export const GET_INVITED_MEMBER_DATA = 'account/get-invited-member-data/:memberToken';
export const POST_SIGN_UP_INVITED_MEMBER = 'account/sign-up-invited-member';

// ONBOARDING
export const UPDATE_ONBOARDING_PROGRESS = 'account/update-onboarding';
export const RESET_ONBOARDING_PROGRESS = 'account/reset-onboarding';
export const SET_ACCOUNT_ROLE = 'account/set-role';

export const PLATFORM_STATS_ROUTE = 'platform-infos';

// ACCOUNT
export const ACCOUNT_PASSWORD_ROUTE = 'account/password';
export const DOWNGRADE_ROUTE = 'account/downgrade';
export const DELETE_ACCOUNT_ROUTE = 'account/deleteLoggedUser';

// HYPOTHESIS
export const GET_HYPOTHESIS_DETAIL_ROUTE = 'hypothesis-detail/get-by-team/:teamId';

// EXPERIMENT
export const GET_EXPERIMENT_DETAIL_ROUTE = 'experiment-detail/get-by-team/:teamId';

// SEGMENT DIMENSION
export const GET_SEGMENT_DIMENSION = 'segmentDimensions/get-by-hypothesis/:hypothesisId';
export const DELETE_SEGMENT_DIMENSION = 'segmentDimensions/delete/:segmentId';
export const EDIT_SEGMENT_DIMENSION = 'segmentDimensions/edit/:segmentId';
export const ADD_SEGMENT_DIMENSION = 'segmentDimensions/add';

// VIABILITY MODELS
export const GET_VIABILITY_MODEL = 'viabilityModels/get/teams/:teamId';
export const GET_VIABILITY_MODEL_STATUS = 'viabilityModels/get-viability-status/teams/:teamId';
export const REMOVE_VIABILITY_MODEL_DATA = 'viabilityModels/remove-data/:viabilityModelId';
export const EDIT_VIABILITY_MODEL = 'viabilityModels/edit/:viabilityModelId';
export const GET_VIABILITY_ACCOMPLISHED_DATA = 'viabilityModels/accomplished-data-computations/teams/:teamId';

// MRL
export const GET_MRL_STEPS_ROUTE = 'mrl/find-all';
export const GET_MRL_STEP_ROUTE = 'mrl/get/:stepId';
export const EDIT_MRL_STEP_ROUTE = 'mrl/update/:stepId';
export const ORDER_MRL_ROUTE = 'mrl/order/update';
export const CREATE_MRL_STEP_ROUTE = 'mrl/add';
export const DELETE_MRL_STEP_ROUTE = 'mrl/remove/:stepId';

// MRL TASKS
export const GET_MRL_TASKS_ROUTE = 'mrl/:stepId/task/findAll';
export const GET_MRL_TASK_ROUTE = 'mrl/:stepId/task/get/:taskId';
export const DELETE_MRL_TASK_ROUTE = 'mrl/:stepId/task/remove/:taskId';
export const EDIT_MRL_TASK_ROUTE = 'mrl/:stepId/task/edit/:taskId';
export const ORDER_MRL_STEP_ROUTE = 'mrl/:stepId/task/order/update';
export const CREATE_MRL_TASK_ROUTE = 'mrl/:stepId/task/add';
