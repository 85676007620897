import { formatUserName } from '../../utils/formatters';

export const getMembersAsOptions = (team, userId) => {
  return team?.members
    .filter((member) => member.userId !== userId)
    .map(({ userProfileId, userId }) => ({ label: formatUserName(userProfileId), value: userId }));
};

export const getTeamsAsOptions = (teams) => {
  return teams
    .map((team) => ({ label: team.name, value: team._id }));
};
