import { Typography } from '@mui/material';
import React from 'react';
import { Table } from 'react-bootstrap';
import { useIntl } from 'react-intl';

import { FeatureSign, NoFeatureSign, Root, SubscriptionsTableContainer } from './index.styled';

const PlansDescription = () => {
  const intl = useIntl();

  return (
    <Root>
      <SubscriptionsTableContainer className="scrollbar">
        <Table>
          <tbody>
          <tr>
            <td className="pl-0">
              <Typography variant="h3" color="textPrimary">
                {intl.formatMessage({id: 'label.characteristic'})}
              </Typography>
            </td>
            <td className="vertical-center">
              <Typography variant="h3" color="textPrimary">
                {intl.formatMessage({id: 'label.explorer'}, {count: 1})}
              </Typography>
            </td>
            <td className="vertical-center">
              <Typography variant="h3" color="textPrimary">
                {intl.formatMessage({id: 'label.premium'})}
              </Typography>
            </td>
          </tr>

          <tr>
            <Typography variant="h2" color="textPrimary" mt={40}>
              {intl.formatMessage({id: 'label.ideasAndTeam'})}
            </Typography>
          </tr>

          <tr>
            <td className="pl-0">
              <Typography fontWeight={700} color="textPrimary" gutterBottom>
                {intl.formatMessage({id: 'label.ideas'})}
              </Typography>
              <Typography color="textPrimary">
                {intl.formatMessage({id: 'label.maxNoOfIdeas'})}
              </Typography>
            </td>
            <td className="vertical-center">
              <Typography color="textPrimary">
                {intl.formatMessage({id: 'label.nrOfIdeas'}, {count: 1})}
              </Typography>
            </td>
            <td className="vertical-center">
              <Typography color="textPrimary">
                {intl.formatMessage({id: 'label.unlimited'})}
              </Typography>
            </td>
          </tr>

          <tr>
            <td className="pl-0">
              <Typography fontWeight={700} color="textPrimary" gutterBottom>
                {intl.formatMessage({id: 'label.collaboration'})}
              </Typography>
              <Typography color="textPrimary">
                {intl.formatMessage({id: 'label.maxNoOfTeamMembers'})}
              </Typography>
            </td>
            <td className="vertical-center">
              <Typography color="textPrimary">
                {intl.formatMessage({id: 'label.unlimited'})}
              </Typography>
            </td>
            <td className="vertical-center">
              <Typography color="textPrimary">
                {intl.formatMessage({id: 'label.unlimited'})}
              </Typography>
            </td>
          </tr>

          <tr>
            <td className="pl-0">
              <Typography fontWeight={700} color="textPrimary" gutterBottom>
                {intl.formatMessage({id: 'label.mentors'})}
              </Typography>
              <Typography color="textPrimary">
                {intl.formatMessage({id: 'label.maxNoOfMentors'})}
              </Typography>
            </td>
            <td className="vertical-center">
              <Typography color="textPrimary">
                {intl.formatMessage({id: 'label.mentorsPerIdea'}, {count: 1})}
              </Typography>
            </td>
            <td className="vertical-center">
              <Typography color="textPrimary">
                {intl.formatMessage({id: 'label.unlimited'})}
              </Typography>
            </td>
          </tr>

          <tr>
            <td className="pl-0">
              <Typography fontWeight={700} color="textPrimary" gutterBottom>
                {intl.formatMessage({id: 'label.participationInAcceleratorPrograms'})}
              </Typography>
              <Typography color="textPrimary">
                {intl.formatMessage({id: 'label.maxNoOfPrograms'})}
              </Typography>
            </td>
            <td className="vertical-center">
              <NoFeatureSign>
                ⊘
              </NoFeatureSign>
            </td>
            <td className="vertical-center">
              <FeatureSign>
                ✓
              </FeatureSign>
            </td>
          </tr>

          <tr>
            <Typography variant="h2" color="textPrimary" mt={80}>
              {intl.formatMessage({id: 'label.basicTools'})}
            </Typography>
          </tr>

          <tr>
            <td className="pl-0">
              <Typography fontWeight={700} color="textPrimary" gutterBottom>
                {intl.formatMessage({id: 'label.businessModelCanvas'})}
              </Typography>
              <Typography color="textPrimary">
                {intl.formatMessage({id: 'label.businessModelCanvasDescription'})}
              </Typography>
            </td>
            <td className="vertical-center">
              <FeatureSign>
                ✓
              </FeatureSign>
            </td>
            <td className="vertical-center">
              <FeatureSign>
                ✓
              </FeatureSign>
            </td>
          </tr>

          <tr>
            <td className="pl-0">
              <Typography fontWeight={700} color="textPrimary" gutterBottom>
                {intl.formatMessage({id: 'label.contextAnalysis'})}
              </Typography>
              <Typography color="textPrimary">
                {intl.formatMessage({id: 'label.contextAnalysisDescription'})}
              </Typography>
            </td>
            <td className="vertical-center">
              <FeatureSign>
                ✓
              </FeatureSign>
            </td>
            <td className="vertical-center">
              <FeatureSign>
                ✓
              </FeatureSign>
            </td>
          </tr>

          <tr>
            <td className="pl-0">
              <Typography fontWeight={700} color="textPrimary" gutterBottom>
                {intl.formatMessage({id: 'label.guideMarketReadinessLevel'})}
              </Typography>
              <Typography color="textPrimary">
                {intl.formatMessage({id: 'label.guideMarketReadinessLevelDescription'})}
              </Typography>
            </td>
            <td className="vertical-center">
              <FeatureSign>
                ✓
              </FeatureSign>
            </td>
            <td className="vertical-center">
              <FeatureSign>
                ✓
              </FeatureSign>
            </td>
          </tr>

          <tr>
            <td className="pl-0">
              <Typography fontWeight={700} color="textPrimary" gutterBottom>
                {intl.formatMessage({id: 'label.communicationAndComments'})}
              </Typography>
              <Typography color="textPrimary">
                {intl.formatMessage({id: 'label.communicationAndCommentsDescription'})}
              </Typography>
            </td>
            <td className="vertical-center">
              <FeatureSign>
                ✓
              </FeatureSign>
            </td>
            <td className="vertical-center">
              <FeatureSign>
                ✓
              </FeatureSign>
            </td>
          </tr>
          </tbody>
        </Table>
      </SubscriptionsTableContainer>
    </Root>
  );
};

export default PlansDescription;
