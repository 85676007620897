import { Box, Divider, Typography } from '@mui/material';

import AddButton from '../../../common/components/AddButton';

const SectionHeader = ({
  title,
  onAddClick,
  children,
  sx = { mt: 20 },
}) => (
  <>
    <Box display="flex" justifyContent="space-between" sx={sx} width="100%">
      <Typography variant="h2" color="primary">
        {title}
      </Typography>
      <Box display="flex" alignItems="center">
        {children}
        <AddButton onClick={onAddClick} p={5} />
      </Box>
    </Box>

    <Divider />
  </>
);

export default SectionHeader;
