import { styled } from '@mui/material/styles';

export const Option = styled('li')(({ theme }) =>  `
  border-radius: 5px;
        
  &:hover {
     background-color: ${theme.palette.general.accentLight};
  }
  
  & .activeNavItem {
    background-color: ${theme.palette.common.white};
    border-radius: 5px;
    
    & span {
      color: ${theme.palette.primary.main};
    }
    
    & svg > path:not(#mandatory-color), & svg > g > path, & svg > rect {
      fill: ${theme.palette.primary.main};
    }
  }
`);
