import { Popover, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { FilterIcon } from '../../../assets/icons';

import { Root } from './index.styled';

export const FilterButton = ({
  counter = 0,
  children,
  sx = {},
  popperSx = {},
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const popperOpen = Boolean(anchorEl);

  const handleToggleFilters = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  return (
   <>
     <Root onClick={handleToggleFilters} sx={sx}>
       <FilterIcon />
       <Typography variant="body2" fontWeight={600} ml={4} color="primary">
         {`${intl.formatMessage({ id: 'label.filters' })} (${counter})`}
       </Typography>
     </Root>

     {popperOpen && (
       <Popover
         id="simple-popper"
         open={popperOpen}
         anchorEl={anchorEl}
         onClose={handleToggleFilters}
         transformOrigin={{ horizontal: 'right', vertical: 'top' }}
         anchorOrigin={{
           vertical: 'bottom',
           horizontal: 'right',
         }}
         slotProps={{
           paper: {
             sx: {
               padding: theme.spacing(15, 15, 15, 6),
               border: `1px solid ${theme.palette.general.accentLight}`,
               ...popperSx,
             },
           },
         }}
       >
         {children({ onToggleFilters: handleToggleFilters })}
       </Popover>
     )}
   </>
  );
};

export default FilterButton;
