import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

import ButtonComponent from '../../../../common/components/ButtonComponent';
import { WhiteCard } from '../../../../common/styled';

import { getInvitedMemberData } from '../../../../requests/auth';
import { getTeamById } from '../../../../requests/teams';
import { acceptTeamInvite, rejectTeamInvite } from '../../../../requests/teams';

import { USER_ROLE_NAMES } from '../../../../constants/roles';
import { handleApiError } from '../../../../utils/errorUtils';
import { avatarInitials } from '../../../../utils/formatters';

import { AccountPage, AccountPanel, IdeeaMemberAvatar } from '../../index.styled';

const JoinTeam = () => {
  const intl = useIntl();
  const query = new URLSearchParams(useLocation().search);
  const userToken = query.get('invitedUser');
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [invitationData, setInvitationData] = useState(null);
  const isLoggedIn = useSelector((state) => state?.account?.isLoggedIn);

  useEffect(() => {
    if (!userToken) {
      return history.push('/canvas');
    }

    if (!isLoggedIn) {
      return history.push(`/account/login?redirectToAccept=${userToken}`);
    }

    async function initializeData() {
      try {
        const { data: userData } = await getInvitedMemberData({ memberToken: userToken });
        const { data: team } = await getTeamById({ teamId: userData?.teamId });
        const creator = team?.members?.find(({ _id }) => _id === team.createdBy);
        setInvitationData({
          userData,
          team,
          creator
        });
      } catch (e) {
        handleApiError(e);
      } finally {
        setIsLoading(false);
      }
    }

    initializeData();
  }, []);

  const handleRejectTeamInvite = async () => {
    try {
      setIsSubmitting(true);
      await rejectTeamInvite({ memberToken: userToken });
      history.push('/');
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAcceptTeamInvite = async () => {
    try {
      setIsSubmitting(true);
      await acceptTeamInvite({ memberToken: userToken });
      history.push('/');
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderBold = (chunks) => <b>{chunks}</b>;

  return (
    <AccountPage>
      <AccountPanel isLoading={isLoading}>
        <WhiteCard noOverflow padding="30px" borderRadius="10px">
          <Box>
            <Typography variant="body1" textAlign="center">
              {intl.formatMessage(
                { id: 'label.youBeenInvitedByAndAs' },
                {
                  name: invitationData?.team?.name,
                  role: invitationData?.userData?.userRole
                    ? intl.formatMessage({ id: USER_ROLE_NAMES[invitationData?.userData?.userRole] })
                    : '-',
                  b: renderBold,
                }
              )}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 10, mt: 16 }}>
            {invitationData?.team?.members.map((member) => (
              <IdeeaMemberAvatar
                key={member?.userId}
                src={member?.profilePicture}
                size={45}
                variant="circle"
              >
                {avatarInitials(member)}
              </IdeeaMemberAvatar>
            ))}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 20, mt: 32 }}>
            <ButtonComponent
              label={intl.formatMessage({ id: 'button.rejectInvite' })}
              onClick={handleRejectTeamInvite}
              variant="outlined"
              color="error"
              disabled={isSubmitting}
            />
            <ButtonComponent
              label={intl.formatMessage({ id: 'button.acceptInvite' })}
              onClick={handleAcceptTeamInvite}
              color="primary"
              disabled={isSubmitting}
            />
          </Box>
        </WhiteCard>
      </AccountPanel>
    </AccountPage>
  );
};

export default JoinTeam;
