import { DEFAULT_DESCRIPTION } from '../../constants/general';

export const getInitialValues = (article, userLanguage) => ({
  title: article?.title || { [userLanguage]: '' },
  description: article?.description || { [userLanguage]: DEFAULT_DESCRIPTION },
  subtitle: article.subtitle || { [userLanguage]: '' },
  category: article.categoryId || '',
  isHidden: !article?.isVisible || false,
});

export const formatPayload = (values, userLanguage) => ({
  ...(values.title?.[userLanguage] !== '' ? { title: values.title } : {}),
  ...(values.subtitle?.[userLanguage] !== '' ? { subtitle: values.subtitle } : {}),
  ...(values.description?.[userLanguage] !== '' ? { description: values.description } : {}),
  ...(values.category !== '' ? { categoryId: values.category } : {}),
  ...(values.isHidden !== '' ? { isVisible: !values.isHidden } : {}),
});
