import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import DialogAlert from '../../../../common/components/DialogAlert';
import DialogComponent from '../../../../common/components/DialogComponent';

import { loadUserTeams } from '../../../../common/store';
import { deleteTeamById } from '../../../../requests/teams';

import { handleApiError } from '../../../../utils/errorUtils';
import { getMembersAsOptions } from '../../../userSettings/utils';

import AutocompleteField from '../../../../fields/AutocompleteField';

const DeleteType = {
  warning: 'warning',
  transfer: 'transfer',
};

const LeaveIdea = ({ team, onCloseDeleteIdea }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [deleteType, setDeleteType] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newUser, setNewUser] = useState(null);
  const loggedUser = useSelector((state) => state.userProfile.userProfile?.userId);
  const isOwner = team.createdBy === loggedUser._id;

  useEffect(() => {
    if (team?._id) {
      handleOpenModal();
    }
  }, [team?._id]);

  const handleOpenModal = () => {
    if (isOwner) {
      const existingMembers = team.members?.filter(({ isInvited }) => !isInvited);

      if (!existingMembers || existingMembers?.length === 1) {
        setDeleteType(DeleteType.warning);
      } else {
        setDeleteType(DeleteType.transfer);
      }
    } else {
      const isFounderWithEquity = team.members.find(({ equity, userId }) => userId === loggedUser?._id && equity);

      if (isFounderWithEquity) {
        setDeleteType(DeleteType.transfer);
      } else {
        setDeleteType(DeleteType.warning);
      }
    }
  };

  const deleteIdea = async () => {
    try {
      setIsSubmitting(true);
      const payload = {
        ...(isOwner ? { newOwnerId: 'none' } : {}),
        ...(team.members.find(({ userId }) => userId === loggedUser?._id)
          ? { transferEquityToId: 'none' }
          : {}),
      };
      await deleteTeamById(team._id, payload);
      dispatch(loadUserTeams(loggedUser?._id));
      handleCloseModal();
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const deleteWithTransferIdea = async () => {
    try {
      setIsSubmitting(true);

      if (isOwner) {
        await deleteTeamById(team._id, { newOwnerId: newUser?.value || 'none' });
      } else {
        await deleteTeamById(team._id, { transferEquityToId: newUser?.value || 'none' });
      }

      dispatch(loadUserTeams(loggedUser?._id));
      handleCloseModal();
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCloseModal = () => {
    onCloseDeleteIdea();
    setDeleteType(null);
  };

  const renderNewLine = () => <br />;
  const handleUserSelect = (e, selectedUser) => {
    setNewUser(selectedUser);
  };

  return (
    <>
      {deleteType === DeleteType.warning && (
        <DialogAlert
          isModalOpen
          onClose={handleCloseModal}
          onSubmit={deleteIdea}
          hasActions
          submitLabel={isOwner ? 'button.deleteIdea' : 'button.leaveIdea'}
          isDisabled={isSubmitting}
          title={intl.formatMessage({ id: isOwner ? 'modal.title.deleteIdea' : 'modal.title.leaveIdea' })}
          content={intl.formatMessage(
            { id: isOwner ? 'modal.content.deleteIdea' : 'modal.content.leaveIdea' },
            { br: renderNewLine }
          )}
        />
      )}

      {deleteType === DeleteType.transfer && (
        <DialogComponent
          open
          hasActions
          color="error"
          submitLabel={isOwner && !newUser ? 'button.deleteIdea' : 'button.leaveIdea'}
          onSubmit={deleteWithTransferIdea}
          handleClose={handleCloseModal}
          title={intl.formatMessage({ id: 'modal.title.deleteIdea' })}
        >
          <div>
            <Typography mb={16}>
              {isOwner
                ? intl.formatMessage({ id: 'modal.content.deleteIdea' })
                : intl.formatMessage({ id: 'modal.content.leaveIdea' })}
            </Typography>

            <Typography mb={16}>
              {isOwner
                ? intl.formatMessage({ id: 'modal.content.deleteIdeaTransferOwnership' })
                : intl.formatMessage({ id: 'modal.content.deleteIdeaTransferEquity' })}
            </Typography>

            <Box mb={16}>
              <AutocompleteField
                label={intl.formatMessage({id: isOwner ? 'label.transferOwnership' : 'label.transferOwnership' })}
                selectedOption={newUser}
                onSelect={handleUserSelect}
                options={getMembersAsOptions(team, loggedUser._id)}
              />
            </Box>
          </div>
        </DialogComponent>
      )}
    </>
  );
};

export default LeaveIdea;
