import { Box, Divider, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import Iframe from '../../../../common/components/Iframe';
import { Loading } from '../../../../common/styled';

const Resource = ({ resource, isLoading }) => {
  const userLanguage = useSelector(state => state?.userProfile?.userProfile?.language);

  return (
    <>
      <Typography variant="h1" color="primary" mb={8}>{resource.title?.[userLanguage]}</Typography>

      <Divider sx={{ borderColor: 'general.lightBlue', borderWidth: '1.5px' }} />

      <Box
        mt={20}
        display="flex"
        flexDirection="column"
        gap={10}
      >
        <Iframe title="Preview" content={resource.description?.[userLanguage]} hasDynamicHeight />
      </Box>

      <Loading isLoading={isLoading} />
    </>
  );
};

export default Resource;
