import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import { useIntl } from 'react-intl';

import ButtonComponent from '../../../../common/components/ButtonComponent';

import { formatSubscriptionPrice } from '../../../../utils/formatters';

import { Description, SubscriptionBox, FeatureSign } from './index.styled';

const PlanOptions = ({
   isYearlyActive,
   product,
   onUpgrade,
   onCancelSubscription,
   currentSubscription,
   isLoading,
 }) => {
  const intl = useIntl();
  const currentSubscriptionPrice = formatSubscriptionPrice(currentSubscription?.amountDecimal);
  const recurrence = intl.formatMessage({ id: isYearlyActive ? 'label.year' : 'label.month' });

  return (
    <SubscriptionBox isFree={product.isFree}>
      <Box p={20}>
        <Typography sx={{ mb: 30 }} variant="h2" fontWeight={700} textAlign="right">
          {product.name}
        </Typography>
        <Typography variant="h1">
          {product.isFree
            ? intl.formatMessage({ id: 'label.free' })
            : `${product.price} lei`}
        </Typography>
        <Typography>
          {intl.formatMessage({ id: 'label.perPeriod' }, { period: recurrence })}
        </Typography>

        <Typography my={16}>
          {product.description}
        </Typography>

        {product.isFree && !currentSubscription?.cancelAt && (
          <ButtonComponent
            sx={{ mt: 30 }}
            fullWidth
            disabled={isLoading || !currentSubscriptionPrice}
            onClick={onCancelSubscription}
            label={(
              intl.formatMessage({
                id: currentSubscriptionPrice
                  ? 'label.unsubscribe'
                  : 'label.currentPlan'
              })
            )}
          />
        )}
        {!product.isFree && (
          <ButtonComponent
            sx={{ mt: 30 }}
            fullWidth
            disabled={currentSubscriptionPrice === product.price}
            onClick={() => onUpgrade(product)}
            label={(
              intl.formatMessage({
                id: currentSubscriptionPrice === product.price
                  ? 'label.currentPlan'
                  : 'label.upgrade'
              })
            )}
          />
        )}
      </Box>

      <Description>
        <Typography variant="h3" mb={16}>
          {intl.formatMessage({ id: 'label.include' })}:
        </Typography>
        {product.features?.map((feature, index) => (
          <Typography key={index} gutterBottom>
            <FeatureSign>
              ✓
            </FeatureSign>
            {feature}
          </Typography>
        ))}
      </Description>
    </SubscriptionBox>
  );
};

export default PlanOptions;
