import { Popover, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import AvatarImage from '../../components/AvatarImage';

import { ArrowDown } from '../../../assets/icons';
import GradientPicture from '../../../assets/img/gradient_picture.jpg';

import { TeamBox, TeamOption } from './index.styled';

const TeamSelector = ({
  userTeams = [],
  userTeamValue,
  handleChangeTeam,
}) => {
  const intl = useIntl();
  const { label: selectedLabel } = userTeamValue;
  const [anchorEl, setAnchorEl] = useState(null);
  const popperOpen = Boolean(anchorEl);
  const hasMultipleTeams = userTeams.length > 1;

  const handleOpenPopper = (event) => {
    if (hasMultipleTeams) {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    }
  };

  const handleChooseTeam = (team) => {
    handleChangeTeam(team);
    setAnchorEl(null);
  };

  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <TeamBox
        onClick={handleOpenPopper}
        hasMultiple={hasMultipleTeams}
        id="team-dropdown"
      >
        <AvatarImage
          width="24px"
          height="24px"
          src={userTeamValue?.logo || GradientPicture}
          medium
        />
        <Typography variant="body1" fontWeight="600">
          {selectedLabel || intl.formatMessage({ id: 'label.myTeam' })}
        </Typography>
        <ArrowDown size="18px" />
      </TeamBox>
      <Popover
        open={popperOpen}
        anchorEl={anchorEl}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onClose={handleClose}
      >
        <Box width="200px">
          {userTeams.map((team) => (
            <TeamOption
              key={team?.value}
              onClick={() => handleChooseTeam(team)}
              isSelected={team?.value === userTeamValue?.value}
            >
              <Typography variant="body1">
                {team.label}
              </Typography>
            </TeamOption>
          ))}
        </Box>
      </Popover>
    </>
  );
};

export default TeamSelector;
