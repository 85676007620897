import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

import AddButton from '../../components/AddButton';
import MemberRole from '../../components/MemberRole';

import { avatarInitials, formatUserName } from '../../../utils/formatters';
import { canInviteUsers } from '../../../utils/permissions';

import MemberMenu from '../MemberMenu';
import {
  MemberRowContainer,
  MembersCounter,
  MemberTypeContainer,
  MemberAvatar,
} from './index.styled';

const EmptyState = ({ placeholder }) => (
  <Box>
    <Typography className="faded italic" mt={10}>
      {placeholder}
    </Typography>
  </Box>
);

const MemberDetails = ({
  title,
  placeholder,
  userList = [],
  children,
  openModal,
  loggedUser,
  type,
  runUpdateTeamMembers,
  hasActions = false,
}) => {
  const intl = useIntl();

  const getUserName = (user) => (
    `${formatUserName(user)} ${user?.isInvited ? `(${intl.formatMessage({ id: 'label.invitedAccount' })})` : ''}`
  );

  return (
    <MemberTypeContainer>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={20}
      >
        <Box display="flex" alignItems="center">
          <Typography variant="h2" color="primary">
            {title}
          </Typography>
          <MembersCounter>
            <Typography variant="body2">{userList.length || 0}</Typography>
          </MembersCounter>
        </Box>
        <Box display="flex" alignItems="center" gap={8}>
          {children}
          {hasActions && canInviteUsers(loggedUser) && (
            <AddButton p={6.5} color="secondary" onClick={openModal} />
          )}
        </Box>
      </Box>

      {userList.length ? (
        <Box display="flex" flexDirection="column">
          {userList.map((user, index) => (
            <MemberRowContainer key={index}>
              <Box display="flex">
                <MemberAvatar src={user?.profilePicture} variant="circle">
                  {avatarInitials(user)}
                </MemberAvatar>
                <Box ml={10}>
                  <Typography fontWeight={700} color="primary">
                    {getUserName(user)}
                  </Typography>
                  <Typography variant="body2" color="primary">
                    {user?.email}
                  </Typography>
                  <MemberRole user={user} index={index} />
                </Box>
              </Box>
              {hasActions && type === 'members' && (
                <Box>
                  <MemberMenu member={user} runUpdateTeamMembers={runUpdateTeamMembers} />
                </Box>
              )}
            </MemberRowContainer>
          ))}
        </Box>
      ) : (
        <EmptyState placeholder={placeholder} />
      )}
    </MemberTypeContainer>
  );
};

export default MemberDetails;
