import React from 'react';
import { Switch, Route, withRouter } from 'react-router';
import { ToastContainer, Slide } from 'react-toastify';

import IconsList from './common/components/IconsList';
import Page from './layouts/Page';
import NotificationsPageResolver from './modules/commentsAndNotifications/components/NotificationsPageResolver';
import { ContextAnalysis } from './modules/contextAnalysis/containers/ContextAnalysis';
import { KPIPageResolver } from './modules/kpis/components';
import BusinessModelCanvasPageResolver from './pages/BusinessModelCanvasPageResolver';
import HypothesisDetailsPageResolver from './pages/HypothesisDetailsPageResolver';
import LeaderboardPageResolver from './pages/LeaderboardPageResolver';
import MentorsPageResolver from './pages/MentorsPageResolver';
import MyIdea from './pages/MyIdea';
import OnboardingPage from './pages/OnboardingPage';
import ProfilePageResolver from './pages/ProfilePageResolver';
import { ResourcesPage } from './pages/ResourcesPage';
import AccountRouter from './routes/AccountRouter';
import AdminRouter from './routes/AdminRouter';
import AuthRoute from './routes/AuthRoute';
import GuestRoute from './routes/GuestRoute';

import JoinTeam from './modules/auth/containers/JoinTeam';
import Login from './modules/auth/containers/Login';
import BmcModelsPage from './modules/bmcModels/containers/BmcModelsPage';
import { BoardsPage } from './modules/boards/containers/Boards';
import TeamDetails from './modules/communityIdeaDetails/containers/IdeaDetails';

// import { TeamProfilePageResolver } from './modules/team/pages';
// import { HypothesisResolutionPageResolver } from './modules/resolutions/pages';
// import { HypothesisDetailPageResolver } from './_deprecated/hypotesisDetails/pages';
// import { DetailsPageResolver } from './_deprecated/details/components';
// import {
//   CohortsPageResolver,
//   CohortTeamsPageResolver,
//   TeamCanvasPageResolver,
//   TeamHypothesesPageResolver,
//   TeamProfilePageResolver as CohortTeamProfilePageResolver,
//   CohortLeaderboardPageResolver,
// } from './modules/accelerator/pages';
// import {
//   HypothesesPageResolver,
//   HypothesisDetailsPageResolver,
// } from './modules/hypotheses/pages';

const ProtectedRouter = () => (
  <Page>
    <AuthRoute path="/" exact component={() => <></>} />
    <AuthRoute path="/icons" exact component={IconsList} />
    <AuthRoute path="/canvas" component={BusinessModelCanvasPageResolver} />
    <AuthRoute path="/canvas-models" component={BmcModelsPage} />
    <AuthRoute path="/resources" component={ResourcesPage} />
    <AuthRoute path="/boards" component={BoardsPage} />
    <AuthRoute path="/community" exact component={LeaderboardPageResolver} />
    <AuthRoute path="/community/teamDetails/:teamId" component={TeamDetails} />
    <AuthRoute path="/notifications" component={NotificationsPageResolver} />
    <AuthRoute path="/mentors" component={MentorsPageResolver} />
    <AuthRoute path="/profile" component={ProfilePageResolver} />
    <AuthRoute path="/opportunity-analysis" component={ContextAnalysis} />
    <AuthRoute path="/idea" exact component={MyIdea} />
    <AuthRoute
      path="/hypothesis/:hypothesisId"
      exact
      component={HypothesisDetailsPageResolver}
    />
    <AuthRoute path="/kpis" exact component={KPIPageResolver} />
    {/*<AuthRoute*/}
    {/*  path="/mentor-invitations"*/}
    {/*  component={MentorsInvitationsPageResolver}*/}
    {/*/>*/}
    {/*<AuthRoute path="/team-profile" component={TeamProfilePageResolver} />*/}
    {/*<AuthRoute path="/hypotheses" component={HypothesesPageResolver} />*/}
    {/*<AuthRoute*/}
    {/*  path="/hypothesis/:hypothesisId/resolution/:resolutionId"*/}
    {/*  exact*/}
    {/*  component={HypothesisResolutionPageResolver}*/}
    {/*/>*/}
    {/*<AuthRoute*/}
    {/*  path="/hypothesis/:hypothesisId/empathy-map"*/}
    {/*  exact*/}
    {/*  component={EmpathyMapPageResolver}*/}
    {/*/>*/}
    {/*<AuthRoute*/}
    {/*  path="/hypothesis/:hypothesisId/detail/:detailId"*/}
    {/*  exact*/}
    {/*  component={HypothesisDetailPageResolver}*/}
    {/*/>*/}
    {/*<AuthRoute path="/cohorts" exact component={CohortsPageResolver} />*/}
    {/*<AuthRoute*/}
    {/*  path="/accelerator/:cohortId/teams"*/}
    {/*  exact*/}
    {/*  component={CohortTeamsPageResolver}*/}
    {/*/>*/}
    {/*<AuthRoute*/}
    {/*  path="/accelerator/:cohortId/teams/:teamId/profile"*/}
    {/*  exact*/}
    {/*  component={CohortTeamProfilePageResolver}*/}
    {/*/>*/}
    {/*<AuthRoute*/}
    {/*  path="/accelerator/:cohortId/teams/:teamId/canvas"*/}
    {/*  exact*/}
    {/*  component={TeamCanvasPageResolver}*/}
    {/*/>*/}
    {/*<AuthRoute*/}
    {/*  path="/accelerator/:cohortId/teams/:teamId/hypotheses"*/}
    {/*  exact*/}
    {/*  component={TeamHypothesesPageResolver}*/}
    {/*/>*/}
    {/*<AuthRoute*/}
    {/*  path="/accelerator/:cohortId/leaderboard"*/}
    {/*  exact*/}
    {/*  component={CohortLeaderboardPageResolver}*/}
    {/*/>*/}
    {/*<AuthRoute path="/details" exact component={DetailsPageResolver} />*/}
  </Page>
);

const AppRouter = () => (
  <>
    <Switch>
      <GuestRoute path="/" exact component={Login} />
      <Route path="/join" exact component={JoinTeam} />
      <Route path="/account" component={AccountRouter} />
      <AuthRoute path="/form" component={OnboardingPage} />
      <AuthRoute path="/admin" component={AdminRouter} />

      <Route component={ProtectedRouter} />
    </Switch>

    <ToastContainer transition={Slide} closeButton={false} />
  </>
);

export default withRouter(AppRouter);
