import { Box, FormHelperText } from '@mui/material';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import ContentState from 'draft-js/lib/ContentState';
import { useFormikContext } from 'formik';
import { get } from 'lodash-es';
import React, { useState, useImperativeHandle, useRef } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { getBlockStyle } from '../../admin/constants/MrlEditor';
import { DESCRIPTION_LONG_MAX_SIZE } from '../../constants/general';
import { PLATFORM_TOOLBAR_CONFIG } from '../../constants/richTextEditor';
import { convertDraftToPlainText } from '../../utils/editorUtils';

import { Label, PlatformEditorContainer, Root } from './index.styled';

// eslint-disable-next-line react/display-name
const RenderRichText = React.forwardRef((props, ref) => {
  const editorRef = useRef(null);
  const { values, errors, touched, setFieldValue } = useFormikContext();
  const {
    config = PLATFORM_TOOLBAR_CONFIG,
    height = '300px',
    label,
    name,
    children,
    sx = { mb: 15 },
    maxLength = DESCRIPTION_LONG_MAX_SIZE,
    isCountVisible = false,
  } = props;

  // TODO: change this when we get the formik out from the component
  const helperText = get(touched, name) && get(errors, name);
  const error = get(touched, name) && !!get(errors, name);

  const [editorState, setEditorState] = useState(() => {
    const initialValue = get(values, name);

    if (initialValue) {
      let contentState;
      try {
        const rawContentFromDatabase = JSON.parse(initialValue);
        contentState = convertFromRaw(rawContentFromDatabase);
      } catch (error) {
        // Consider the initialValue as plain text.
        contentState = ContentState.createFromText(initialValue);
      }
      return EditorState.createWithContent(contentState);
    }

    return EditorState.createEmpty();
  });

  const onEditorStateChange = (newEditorState) => {
    const editorContent = convertToRaw(newEditorState.getCurrentContent());
    const hasText = editorContent?.blocks?.some((block) => !!block?.text);

    setEditorState(newEditorState);
    setFieldValue(
      name,
      hasText ? JSON.stringify(convertToRaw(newEditorState.getCurrentContent())) : '',
      true
    );
  };

  const setEditorContent = (rawContent) => {
    const formattedContent = JSON.parse(rawContent);
    const convertedContent = convertFromRaw(formattedContent);
    const newEditorState = EditorState.createWithContent(convertedContent);
    setEditorState(newEditorState);
  };

  const clearEditor = () => {
    const emptyContent = EditorState.createEmpty();
    setEditorState(emptyContent);
    setFieldValue(name, '', true);
  };

  const setContentAndFocus = (rawContent) => {
    const formattedContent = JSON.parse(rawContent);
    const convertedContent = convertFromRaw(formattedContent);
    const newEditorState = EditorState.createWithContent(convertedContent);

    const blockArray = convertedContent.getBlocksAsArray();
    const lastBlock = blockArray[blockArray.length - 1];
    const lastBlockLength = lastBlock ? lastBlock.getLength() : 0;

    const selectionState = newEditorState.getSelection().merge({
      anchorKey: lastBlock.getKey(),
      anchorOffset: lastBlockLength,
      focusKey: lastBlock.getKey(),
      focusOffset: lastBlockLength,
    });

    const endSelectedEditorState = EditorState.forceSelection(
      newEditorState,
      selectionState
    );

    setEditorState(endSelectedEditorState);

    if (editorRef.current) {
      editorRef.current.focusEditor();
    }
  };

  // This hook allows us to call methods on the ref of this component.
  useImperativeHandle(ref, () => ({
    clearEditor,
    setEditorContent,
    setContentAndFocus,
    focusEditor: (editorStateToFocus = editorState) => {
      if (editorRef.current) {
        const selectionState = editorStateToFocus.getSelection();
        const beginningOfContent = selectionState.merge({
          anchorOffset: 0,
          focusOffset: 0,
        });
        const newEditorState = EditorState.forceSelection(
          editorStateToFocus,
          beginningOfContent
        );
        setEditorState(newEditorState);
        editorRef.current.focusEditor();
      }
    },
  }));

  return (
    <Root hasError={error} sx={sx}>
      {label && (
        <Box position="absolute" top="-10px" left="10px" zIndex={1}>
          <Label variant="body1" hasError={error}>{label}</Label>
        </Box>
      )}
      <PlatformEditorContainer
        height={height}
        hasError={error}
        className='rich-text-editor bottom-toolbar'
      >
        <Editor
          editorState={editorState}
          editorClassName="scrollbar editor-override"
          toolbarClassName="rich-toolbar"
          wrapperClassName="rich-wrapper"
          toolbar={config}
          onEditorStateChange={onEditorStateChange}
          blockStyleFn={getBlockStyle}
          ref={editorRef}
        />
        {children}
      </PlatformEditorContainer>
      <Box display="flex" alignItems="center" justifyContent={isCountVisible ? 'flex-end' : 'flex-start'}>
        {helperText && !isCountVisible && <FormHelperText>{helperText}</FormHelperText>}
        {isCountVisible && (
          <FormHelperText>
            {`${convertDraftToPlainText(get(values, name))?.length}/${maxLength}`}
          </FormHelperText>
        )}
      </Box>
    </Root>
  );
});

export default RenderRichText;
